import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { SimpleJsonType } from '../../common/stores/simpleJsonType';
import { extendedRegistrationModelActions } from './extendedRegistrationActions';

const ExtendedRegistrationModel = types.model({
    customerID: types.optional(types.string, ''),
    customerWithCustomerID: types.optional(types.boolean, false),
    errors: types.optional(SimpleJsonType<any>(), {}),
    commonErrors: types.optional(types.array(types.string), [])
}).views((self) => ({
    get flatErrors() {
        const errors: string[] = [];
        const errorValues = Object.values(self.errors);
        errorValues.forEach((ev) => {
            if (ev) {
                errors.push(...ev as any);
            }
        });
        return errors;
    }
})).actions((self: any) => extendedRegistrationModelActions(self));

export interface IExtendedRegistrationModel extends Instance<typeof ExtendedRegistrationModel> { }
export interface IExtendedRegistrationSnapshotOut extends SnapshotOut<typeof ExtendedRegistrationModel> { }
export interface IExtendedRegistrationSnapshotIn extends SnapshotOut<typeof ExtendedRegistrationModel> { }

export type IExtendedRegistrationModelActions = {
    updateValue: <T extends keyof IExtendedRegistrationModel>(elementName: keyof IExtendedRegistrationModel, newValue: IExtendedRegistrationModel[T]) => void;
    validate: () => boolean;
    clearErrors: () => void;
};

export { ExtendedRegistrationModel };