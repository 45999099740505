import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServerRoutes } from '../../../../server/serverRoutes';
import { className } from '../../utils/className';
import Button, { IButtonProps } from './button';
import { commontestids } from '../../../../tests/testids/common';

interface IApplicationHomeButtonProps extends React.PropsWithChildren<Pick<IButtonProps, 'className' >> {
    clientId?: string;
}

export const ApplicationHomeButton = (props: IApplicationHomeButtonProps) => {
    const [t] = useTranslation();
    const redirectUrl = () => {
        if (props.clientId) {
            return `${ServerRoutes.ApplicationHome}?appId=${props.clientId}`;
        }
        return ServerRoutes.ApplicationHome;
    };
    const classNames = className('btn-application-home', 'mt-2', props.className || 'w-100');
    return (<Button testId={commontestids.applicationHome} style="outline-secondary" className={classNames} onClick={() => window.location.assign(redirectUrl())}>
        {props.children || t('ApplicationHome.Button')}
    </Button >);
};