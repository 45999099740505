import { required, pattern, ValidatonService } from '../../../common/validation';
import Helper from '../../../common/helper';
import { ErrorType } from '../ErrorType';

export type CustomerRegistrationErrors = ErrorType<ExtendedRegistrationVerificationModel>;

export class ExtendedRegistrationVerificationModel {
    public customerWithCustomerID = false;

    @required({ errorMessageLabel: 'customer_id_required' })
    @pattern({ pattern: '^[0-3][0-9]{6}$|^K[0-9]{9}$', errorMessageLabel: 'CustomerNumberInvalid' })
    public customerID = '';

    public errors: CustomerRegistrationErrors = {};

    public validate(): boolean {
        const validationService: ValidatonService = new ValidatonService();
        const errors = validationService
            .validate<ExtendedRegistrationVerificationModel>(this) as CustomerRegistrationErrors | null;
        if (errors && !this.customerWithCustomerID) {
            delete errors.customerID;
        }

        this.errors = (errors || {});
        return Helper.isEmpty(this.errors);
    }
}
