import { required } from '../common/validation';
import { PasswordChangeRedirectValidationModel } from './PasswordChangeRedirectValidationModel';

export class LoginValidationModel extends PasswordChangeRedirectValidationModel {
    @required({ errorMessageLabel: 'password is required' })
    public password = '';

    constructor(email: string, password: string) {
        super(email);
        this.password = password;
    }
}
