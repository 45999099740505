import { IAuth0RegistrationMetadataDecoded, Auth0ErrorTypes } from './contracts';
import { WebAuth } from 'auth0-js';
import fedConnectionSelector from './fedConnectionSelector';
import { FedConnectionTypes } from './fedConnectionTypes';
import { Auth0MetaDataSerializer } from './auth0MetaDataSerializer';
import { IUserMetadata } from '@abussc/accessidentity-contracts/auth0/userMetadata';

export class Auth0Service {
    private readonly _DATABASE_CONNECTION = 'Username-Password-Authentication';
    private readonly _TOKKEN_ISSUER = 'login.abus-cloud.com';

    private _webAuth?: WebAuth;
    private _auth0MetaDataSerializer: Auth0MetaDataSerializer;

    constructor(authzeroconfiguration: any) {
        const config = authzeroconfiguration;
        if (config !== null) {
            const params = {
                overrides: {
                    __tenant: config.auth0Tenant,
                    __token_issuer: this._TOKKEN_ISSUER
                },
                domain: config.auth0Domain,
                clientID: config.clientID,
                redirectUri: config.callbackURL,
                responseType: 'code',
                ...config.internalOptions
            };

            this._webAuth = new WebAuth(params);
        }

        this._auth0MetaDataSerializer = new Auth0MetaDataSerializer();
    }

    public login(email: string, password: string): Promise<any> {
        if (this._webAuth) {
            return new Promise((resolve, reject) => {
                this._webAuth!.login(
                    {
                        realm: this._DATABASE_CONNECTION,
                        email,
                        password
                    },
                    (err: any) => {
                        if (err) {
                            reject(err.code || err);
                        } else {
                            resolve(undefined);
                        }
                    }
                );
            });
        }
        return Promise.reject(new Error('webauth not set, running in dev mode ?'));
    }

    public loginFed(connectionType: FedConnectionTypes): Promise<any> {
        if (this._webAuth) {
            const connectionName = fedConnectionSelector(connectionType);

            this._webAuth!.authorize({
                connection: connectionName
            });
            return Promise.resolve();
        }
        return Promise.reject(new Error('webauth not set, running in dev mode ?'));
    }

    public register(email: string, password: string, userMetadata: IAuth0RegistrationMetadataDecoded, language: string): Promise<any> {
        if (this._webAuth) {
            const registrationdata = this._auth0MetaDataSerializer.serialize(userMetadata);
            const metadata: IUserMetadata = {
                ...registrationdata,
                language
            };
            const requestdata = {
                connection: this._DATABASE_CONNECTION,
                email,
                password,
                user_metadata: metadata
            };

            return new Promise((resolve, reject) => {
                this._webAuth!.redirect.signupAndLogin(
                    requestdata as any,
                    (err: any) => {
                        if (err) {
                            reject(err.code || err.name || err.statusCode?.toString() || 'Unexpected error');
                        } else {
                            resolve(undefined);
                        }
                    }
                );
            });
        }
        return Promise.reject(new Error('webauth not set, running in dev mode ?'));
    }

    public getAuth0ErrorType(errorcode: string): Auth0ErrorTypes {
        const errorsmap = {
            email: [
                'email_format',
                'user_exists',
                'username_exists',
                'unauthorized',
                'email is required'
            ],
            password: [
                'invalid_password',
                'password_dictionary_error',
                'password_no_user_info_error',
                'password_strength_error',
                'password_leaked',
                'PasswordHistoryError',
                'PasswordStrengthError',
                'password is required',
                'invalid_user_password',
                'password_change_require'
            ]
        };

        let target = Auth0ErrorTypes.common;

        for (const key in errorsmap) {
            if (Object.prototype.hasOwnProperty.call(errorsmap, key)) {
                const errorDefList = errorsmap[key];
                for (let index = 0; index < errorDefList.length; index++) {
                    const code = errorDefList[index];
                    if (errorcode === code) {
                        if (key === 'email') {
                            target = Auth0ErrorTypes.emailRelated;
                        } else if (key === 'password') {
                            target = Auth0ErrorTypes.passwordRelated;
                        }
                        break;
                    }
                }
            }
        }

        return target;
    }
}