import 'reflect-metadata';
import { equalMetadataKey } from './constants';

export interface IEqualMetadata {
    match: any;
    errorMessageLabel: string;
}

function getEqual<T, K extends keyof T, _M>(target: T, propertyKey: K): IEqualMetadata {
    return Reflect.getMetadata(equalMetadataKey, target, propertyKey as string);
}

export function isEqual(args: IEqualMetadata) {
    return Reflect.metadata(equalMetadataKey, args);
}

export function hasIsEqual<T, K extends keyof T>(target: T, propertyKey: K): boolean {
    const meta = getEqual(target, propertyKey);
    return meta && !!meta.match;
}

export function validateEqual<T, K extends keyof T>(target: T, propertyKey: K): string | null {
    const meta = getEqual(target, propertyKey);
    if (hasIsEqual(target, propertyKey)) {
        if (target[propertyKey] !== meta.match) {
            return meta.errorMessageLabel;
        }
    }
    return null;
}