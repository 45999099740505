import * as React from 'react';

import { Login } from './loginComponent';

import './loginPage.scss';

export const LoginPage = () => {
    return (
        <div className="loginPage responsive-page-grid">
            <Login className="responsive-page-content" />
            <aside className="secondary-area" />
        </div>
    );
};

export default LoginPage;
