import * as React from 'react';

import './screenlockOverlay.scss';
import { useObserver } from 'mobx-react';
import { useStore } from '../../useStore';

const ScreenlockOverlay = () => {
    const { viewStore } = useStore();

    return useObserver(() => {
        return (
            <div className={`d-flex justify-content-center screenlockOverlay ${viewStore!.lockedUI ? 'active' : 'inactive'}`} >
                <div className="align-self-center spinner circles">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    });
};

export default ScreenlockOverlay;