import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nAreaNamespace } from '..';
import { Skeleton } from '../../common/components/skeleton';
import { IRemoteDataSyncStates } from '../../common/stores/remoteDataSyncStateModel';
import { useStore } from '../../useStore';

export const HeaderContent = () => {
    const [t] = useTranslation(I18nAreaNamespace);
    const { consentManagementStore } = useStore();
    const userConsents = consentManagementStore.userConsents;
    return useObserver(() => {
        if (consentManagementStore.userConsents.isDataLoadingState) {
            return <Skeleton className="d-block mb-4" style={{ height: '64px' }} />;
        } else if (consentManagementStore.userConsents.state === IRemoteDataSyncStates.LoadError) {
            return null;
        }
        const appNameText = t([`AppName_${userConsents.applicationName}`, `AppName_${userConsents.applicationId}`]);
        const mainText = t('ConsentManagement.Text1', { appname: appNameText });
        return (<>
            <div className="col-lg-10 col-xl-8">
                <span className="vertical-heading-decorator">
                    <span className="typo-s2">{appNameText}</span>
                    <h2 className="typo-h2">{t('ConsentManagement.Title')}</h2>
                </span>
                <p className="typo-c1-bold">{mainText}</p>
            </div>
        </>);
    });
};
