import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { I18nAreaNamespace } from './index';
import { useStore } from '../useStore';
import { ApplicationHomeButton } from '../common/components/button/applicationHomeButton';
import { ResendButton } from './resendVerificationMailButton';

import './verifyPage.scss';

const SuccessStatusRow = () => {
    const { verifymailStore } = useStore();
    const [t] = useTranslation(I18nAreaNamespace);

    return (<div className="row">
        <div className={`col-2 justify-content-center align-items-start callbackfeedback ${verifymailStore.changeSuccessful ? 'success' : 'failure'}`}>
            <div className="icon-icon_control_confirm" />
        </div>
        <div className={'align-items-center col-8 callbackfeedback success'}>
            <p className="typo-c1">{t(verifymailStore.textLabel)}</p>
        </div>
    </div>);
};

const FailureStatusRow = () => {
    const { verifymailStore } = useStore();
    const [t] = useTranslation(I18nAreaNamespace);

    return (<div className="row justify-content-start callbackfeedback failure">
        <p className="typo-c1">{t(verifymailStore.textLabel)}</p>
    </div>);
};

export const VerifymailCallback = () => {
    const { verifymailStore } = useStore();
    const [t] = useTranslation(I18nAreaNamespace);

    return useObserver(() => {
        return (<div className="verifyPage responsive-page-grid">
            <section className="responsive-page-content">
                <header className="content-header row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <span className="vertical-heading-decorator">
                            <span className="typo-s2">
                                {t(verifymailStore.headlineLabel)}
                            </span>
                            <h2>
                                {t(verifymailStore.titleLabel)}
                            </h2>
                        </span>
                    </div>
                </header>
                <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        {verifymailStore.showSuccessConfirmation && verifymailStore.changeSuccessful && <SuccessStatusRow />}
                        {verifymailStore.showSuccessConfirmation && !verifymailStore.changeSuccessful && <FailureStatusRow />}
                        <div className="row justify-content-start mt-4">
                            <p className="typo-c1 ">
                                <Trans ns={I18nAreaNamespace} defaults={verifymailStore.message} i18nKey={verifymailStore.infoLabel as any} />
                            </p>
                        </div>
                        <div className="row justify-content-start">
                            {!verifymailStore.changeSuccessful && <ResendButton />}
                            {verifymailStore.changeSuccessful && <ApplicationHomeButton />}
                        </div>
                    </div>
                </main>
                <footer className="content-footer transparent-lg-style" />
            </section>
            <aside className="secondary-area" />
        </div>);
    });
};