import React from 'react';
import CustomInput from 'reactstrap/lib/CustomInput';
import { v4 as uuidv4 } from 'uuid';
import { className } from '../../../utils/className';
import { InvalidInputFeedback } from '../../feedback/invalidInputFeedback';
import './labeledCheckbox.scss';

export interface ILabeledCheckboxProps extends React.PropsWithChildren<Record<string, any>> {
    checked: boolean;
    name?: string;
    className?: string;
    onChange?: (e: ILabeledCheckboxChangeEvent) => void;
    appearance?: 'checkbox' | 'radio';
    error?: string;
}

export interface ILabeledCheckboxChangeEvent {
    name: string | undefined;
    value: boolean;
}

export const LabeledCheckbox = (props: ILabeledCheckboxProps) => {
    // Events of CustomInput require an id
    const [id] = React.useState(uuidv4());

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange({ name: props.name, value: e.target.checked });
        }
    };
    const cssClasses = className('abus-custom-checkbox', props.className);
    return (<>
        <CustomInput
            type={props.appearance || 'checkbox'}
            id={id}
            name={props.name}
            className={cssClasses}
            checked={props.checked}
            onChange={(e) => onChange(e)}
        >
            <label
                htmlFor={id}
                className="d-inline typo-c1"
            >
                {props.children}
            </label>
        </CustomInput>
        <InvalidInputFeedback error={props.error} />
    </>);
};