import { flow } from 'mobx-state-tree';
import { IUnverifiedParams } from '../common/stores/viewStore/contracts';
import { getRoot } from '../common/utils/mobx';
import { UnverifiedRepository } from './unverifiedRepository';
import { IUnverifiedStore, IUnverifiedStoreActions } from './unverifiedStore';

export const unverifiedStoreActions = (self: IUnverifiedStore): IUnverifiedStoreActions => {
    return {
        initializeForUnverifiedRoute: (params: IUnverifiedParams) => {
            self.token = params.token;
        },
        resendConfirmationMail: flow(function * () {
            try {
                const unverifiedRepository = new UnverifiedRepository(getRoot(self).viewStore.user.accessToken || self.token);
                yield unverifiedRepository.resendVerificationMail();
                getRoot(self).viewStore.showVerifymailResendCompleted();
            } catch (e) {
                self.error = 'ResendVerificationMailError';
                throw e;
            }
        })
    };
};
