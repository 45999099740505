import React from 'react';
import { className } from '../../utils/className';
import Button, { IButtonProps } from './button';

export const SaveButton = (props: React.PropsWithChildren<Pick<IButtonProps, 'onClick' | 'className' >>) => {
    const classNames = className('mt-2', props.className || 'w-100');
    return (<Button
        onClick={props.onClick}
        className={classNames}
        style="danger"
    >
        {props.children}
    </Button>);
};