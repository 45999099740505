import { validatePattern, hasPattern } from './patternDecorator';
import { validateRequired, hasRequired } from './requiredDecorator';
import { validateMaxLength, hasMaxLength } from './maxLengthDecorator';
import { validateEqual, hasIsEqual } from './equalDecorator';
import Helper from '../helper';

export interface IErrors {
    [key: string]: string[];
}

export class ValidatonService {
    public validate<T extends Record<string, any>>(object: T): IErrors | null {
        const errors: IErrors = {};
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (hasRequired(object, key)) {
                    const err = validateRequired(object, key);
                    if (err != null) {
                        this.addError(errors, key, err);
                    }
                }

                if (hasMaxLength(object, key)) {
                    const err = validateMaxLength(object, key);
                    if (err != null) {
                        this.addError(errors, key, err);
                    }
                }

                if (hasPattern(object, key)) {
                    const err = validatePattern(object, key);
                    if (err != null) {
                        this.addError(errors, key, err);
                    }
                }

                if (hasIsEqual(object, key)) {
                    const err = validateEqual(object, key);
                    if (err != null) {
                        this.addError(errors, key, err);
                    }
                }
            }
        }

        return Helper.isEmpty(errors) ? null : errors;
    }

    private addError(errors: IErrors, key: string, err: string) {
        errors[key] = errors[key] || [];
        errors[key].push(err);
    }
}