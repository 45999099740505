import * as React from 'react';
import {
    FormGroup,
    Input as RSInput,
    FormFeedback,
    FormText
} from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import 'reflect-metadata';

import './input.scss';

export interface IInputProps {
  className?: string;
  placeholder?: string;
  id?: string;
  name: string;
  type: InputType;
  errorMessage?: string[] | null;
  surpressErrorText?: boolean;
  value: string | number | string[] | undefined;
  maxLength?: number;
  hidden?: boolean;
  help?: string;
  onChange?: (name: string, value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  readOnly?: boolean;
}

export interface IInputState {
  randomInputId: string;
}

export default class InputGroup extends React.Component<IInputProps, IInputState> {
  private static idcircle = 0;

  public getInitialState(): IInputState {
      return { randomInputId: this.nextInputId };
  }

  constructor(props: IInputProps) {
      super(props);
      this.state = this.getInitialState();
      this.onChange = this.onChange.bind(this);
  }

  private get nextInputId(): string {
      InputGroup.idcircle = InputGroup.idcircle + 1;
      return `input-group-${InputGroup.idcircle}`;
  }

  private isInvalid(): boolean {
      return !!this.props.errorMessage && this.props.errorMessage.length > 0;
  }

  private getFirstError(): string | null {
      if (this.props.errorMessage && this.props.errorMessage.length > 0) {
          return this.props.errorMessage[0];
      }
      return null;
  }

  private onChange(e: React.SyntheticEvent) {
      const target: HTMLInputElement = e.target as HTMLInputElement;
      const value = target.value;
      if (this.props.onChange) {
          this.props.onChange(this.props.name, value);
      }
  }

  public render() {
      return (
          <FormGroup hidden={this.props.hidden} className={`${this.props.className || ''} abus-input-group`}>
              <RSInput
                  className={this.props.value ? 'used' : ''}
                  invalid={this.isInvalid()}
                  maxLength={this.props.maxLength}
                  readOnly={this.props.readOnly}
                  onChange={this.onChange}
                  onBlur={this.props.onBlur}
                  value={this.props.value || ''}
                  type={this.props.type}
                  name={this.props.name}
                  id={this.props.id || this.state.randomInputId}
              />
              <span className="highlight" />
              <span className="bar" />
              <label htmlFor={this.props.id || this.state.randomInputId}>{this.props.placeholder}</label>
              {this.props.surpressErrorText === true ? null : <FormFeedback>{this.getFirstError()}</FormFeedback>}
              <FormText>{this.props.help}</FormText>
          </FormGroup>
      );
  }
}
