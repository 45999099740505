import React from 'react';
import ReactMarkdown from 'react-markdown';

import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';

import { useStore } from '../useStore';
import { IInformationRouteParams } from '../common/stores/viewStore/contracts';

import './informationPage.scss';

export const InformationPage = () => {
    const [t] = useTranslation();
    const { viewStore } = useStore();
    const routeParams = viewStore.currentView.params as IInformationRouteParams;
    const markdowntext: string = t(`Information.Application=${routeParams.appName.toLowerCase()}.Text=${routeParams.displayTextKey.toLowerCase()}`);
    return useObserver(() => {
        return (<div className="informationPage responsive-page-grid">
            <section className="responsive-page-content">
                <header className="content-header row no-gutters justify-content-center" />
                <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                    <ReactMarkdown>{markdowntext}</ReactMarkdown>
                </main>
                <footer className="content-footer transparent-lg-style row no-gutters justify-content-center pb-3" />
            </section>
            <aside className="secondary-area" />
        </div>);
    });
};
