import { useObserver } from 'mobx-react';
import { SnapshotOut } from 'mobx-state-tree';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { I18nAreaNamespace } from '..';
import { ILabeledCheckboxChangeEvent, LabeledCheckbox } from '../../common/components/formControlls/labeledCheckbox';
import { useStore } from '../../useStore';
import { GDPRTranslationSelector } from '../selectGDPRLabel';
import { ConsentModel } from '../store/consentModel';

export const Consent = (props: { applicationName: string, consent: SnapshotOut<typeof ConsentModel> }) => {
    const { consentManagementStore } = useStore();
    const [t, i18n] = useTranslation(I18nAreaNamespace);
    const labels = new GDPRTranslationSelector({ i18next: i18n, appName: props.applicationName, useFallback: false });
    
    function renderConsentLabelChildren(href: string = labels.getLabelForType(props.consent.consentType)) {
        return [
            <a
                key={0}
                target="_blank"
                href={href} rel="noreferrer"
            >
                <span>/internal_placeholder/</span>
            </a>
        ];
    }

    return useObserver(() => {
        return (<LabeledCheckbox
            checked={props.consent.consentGiven}
            onChange={(e: ILabeledCheckboxChangeEvent) => consentManagementStore.changeConsent(props.consent.consentType, e.value)}
            error={props.consent.isDirty && props.consent.isValid === false ? t(`ConsentManagement.Errors.Consents.Type=${props.consent.consentType}`) : undefined}
        >
            <span
                className="d-inline typo-c1"
            >
                <Trans
                    ns={I18nAreaNamespace}
                    i18nKey={`ConsentManagement.Consents.Type=${props.consent.consentType}`}
                    components={renderConsentLabelChildren()}
                />
                {!props.consent.isOptional && '*'}
            </span>
        </LabeledCheckbox>);
    });
};
