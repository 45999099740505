import { IsSameAs } from './isSameAs';
import { IPasswordresetRequestnapshotIn } from './passwordResetRequest.model';
import { EmailPattern, PasswordPattern } from '../../common/constants';
import { validateSync, Matches, ValidateIf } from 'class-validator';
import { SnapshotIn } from 'mobx-state-tree';
import { ErrorsModel } from '../../common/stores/errorModel';
import { ValidationProps, mapToErrorModel } from './passwordResetRequest.actions';

export class PasswordresetRequestValidator implements ValidationProps {
    @Matches(EmailPattern, { message: 'Errors.Email' })
    public email = '';

    @Matches(PasswordPattern, { message: 'Errors.Password' })
    public password = '';

    @ValidateIf((self: IPasswordresetRequestnapshotIn) => self.password !== '')
    @IsSameAs('password', { message: 'Errors.Passwordrepeat' })
    public passwordrepeat = '';

    constructor(snapshot: IPasswordresetRequestnapshotIn) {
        Object.assign(this, snapshot);
    }

    public validate(): SnapshotIn<typeof ErrorsModel> {
        const validationResult = validateSync(this);
        const errors = mapToErrorModel(validationResult);
        return errors;
    }
}
