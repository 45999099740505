import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/components/button';
import { useStore } from '../useStore';

export const ResendButton = () => {
    const { verifymailStore } = useStore();
    const [t] = useTranslation('aiapp_accountmanagement');

    return (
        <Button
            onClick={() => verifymailStore.startVerificationResendFlow()}
            type="button"
            style="primary"
            className="w-100 mt-2"
        >
            {t('VerifymailCallback.SendVerificationAgain')}
        </Button>
    );
};