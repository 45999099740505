import React from 'react';

import '@abussc/abus-styleguide-kit/scss/icons/icons.scss';
import '@abussc/abus-styleguide-kit/scss/fonts/font.scss';
import './app.scss';

import Header from './common/components/header/header';
import { LoginPage } from './login';
import { RegisterPage } from './register';
import { useObserver } from 'mobx-react';
import { ViewNames } from './common/index';
import CompanyProfilePage from './register/companyProfilePage';
import Footer from './common/components/footer/footer';
import Imprint from './common/components/imprint/imprint';
import Policy from './common/components/policy/policy';
import ScreenlockOverlay from './common/components/screenlockOverlay';
import { useStore } from './useStore';
import { VerifymailCallback } from './verifymail/verifymailCallback';
import { VerifymailSent } from './verifymail/verifymailSent';
import { PasswordResetCallbackPage } from './passwordReset/passwordResetCallbackPage';
import { PasswordResetPage } from './passwordReset/passwordResetPage';
import { NotFound } from './common/components/applicationErrors/notFound';
import { ConsentManagementPage } from './consentManagement/consentManagementPage';
import { UnverifiedPage } from './unverified/unverifiedPage';
import { InformationPage } from './information/informationPage';
import { ErrorPage } from './error/errorPage';

const CurrentView = () => {
    const rootStore = useStore();
    const store = rootStore.viewStore;
    const view = store.currentView;

    return useObserver(() => {
        switch (view.name) {
        case ViewNames.Register:
        case ViewNames.RegisterCustomer:
            return <RegisterPage />;
        case ViewNames.CompanyProfileEditor:
            return <CompanyProfilePage />;
        case ViewNames.Imprint:
            return <Imprint />;
        case ViewNames.Policy:
            return <Policy />;
        case ViewNames.VerifymailCallback:
            return <VerifymailCallback />;
        case ViewNames.VerifymailResendCompleted:
            return <VerifymailSent />;
        case ViewNames.PasswordResetCallback:
            return <PasswordResetCallbackPage />;
        case ViewNames.PasswordReset:
            return <PasswordResetPage />;
        case ViewNames.Login:
            return <LoginPage />;
        case ViewNames.ConsentManagement:
            return <ConsentManagementPage />;
        case ViewNames.Unverified:
            return <UnverifiedPage />;
        case ViewNames.ExtensionsError:
            return <ErrorPage />;
        case ViewNames.Information:
            return <InformationPage />;
        default:
            return <NotFound />;
        }
    });
};

export const App = () => {
    const { viewStore } = useStore();
    return (
        <React.Fragment>
            <ScreenlockOverlay />
            <div className={`App ${viewStore.lockedUI ? 'greyscale' : ''}`}>
                <Header />
                <main>
                    <CurrentView />
                </main>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default App;
