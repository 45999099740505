import { IAuth0RegistrationMetadataDecodedBase } from '@abussc/accessidentity-contracts/auth0/userMetadata';

export enum RegistrationTypes {
    customer = 1,
    consumer = 0
}

export enum Auth0ErrorTypes {
    common,
    passwordRelated,
    emailRelated
}

export interface IAuth0RegistrationMetadataDecoded extends IAuth0RegistrationMetadataDecodedBase<RegistrationTypes> {
}