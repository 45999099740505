import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nAreaNamespace } from '..';
import { SaveButton } from '../../common/components/button/saveButton';
import { LocalizedErrorFeedback } from '../../common/components/localizedErrorFeedback';
import { Skeleton } from '../../common/components/skeleton/skeleton';
import { IRemoteDataSyncStates } from '../../common/stores/remoteDataSyncStateModel';
import { useStore } from '../../useStore';

export const FooterContent = () => {
    const [t] = useTranslation(I18nAreaNamespace);
    const { consentManagementStore } = useStore();
    const userConsents = consentManagementStore.userConsents;
    return useObserver(() => {
        if (consentManagementStore.userConsents.isDataLoadingState) {
            return <>
                <Skeleton className="d-block mb-1" style={{ height: '42px' }} />
                <Skeleton className="d-block mb-1" style={{ height: '42px' }} />
            </>;
        } else if (consentManagementStore.userConsents.state === IRemoteDataSyncStates.LoadError) {
            return null;
        }
        return (<>
            <div className="col-lg-10 col-xl-8">
                <span className="d-block w-100 text-left mt-2 mb-2">{t('ConsentManagement.RequiredMarkInfo')}</span>
                {userConsents.error && <LocalizedErrorFeedback errorKey={userConsents.error} />}
                <SaveButton
                    className="w-100"
                    onClick={() => consentManagementStore.saveConsents()}
                >
                    {t('ConsentManagement.Continue')}
                </SaveButton>
            </div>
        </>);
    });
};
