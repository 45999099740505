import { useObserver } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/components/button';
import { useStore } from '../useStore';
import './unverifiedPage.scss';

export const ErrorText = (props: { errorText: string }) => {
    return (
        <p className="row justify-content-start text-danger mt-5" >{props.errorText}</p>
    );
};

export const UnverifiedPage = () => {
    const [t] = useTranslation('aiapp_register');
    const { unverifiedStore } = useStore();
    return useObserver(() => {
        return (<div className="unverifiedPage responsive-page-grid">
            <section className="responsive-page-content">
                <header className="content-header row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <span className="vertical-heading-decorator">
                            <span className="typo-s2">{t('Unverified.PreHeader')}</span>
                            <h2>{t('Unverified.Header')}</h2>
                        </span>
                    </div>
                </header>
                <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <div className="row justify-content-start mt-4">
                            <p className="typo-c1 ">{t('Unverified.ExplanationText')}</p>
                            <Button
                                onClick={() => unverifiedStore.resendConfirmationMail()}
                                type="button"
                                style="primary"
                            >
                                {t('Unverified.ResendConfirmationMail')}
                            </Button>
                        </div>
                        {unverifiedStore.error && <ErrorText errorText={t(unverifiedStore.error)} />}
                    </div>
                </main>
                <footer className="content-footer transparent-lg-style" />
            </section>
            <aside className="secondary-area" />
        </div>);
    });
};