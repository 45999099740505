import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nAreaNamespace } from './index';

export const VerifymailSent = (props: any) => {
    const { t } = useTranslation(I18nAreaNamespace);

    return (
        <div className="verifyPage responsive-page-grid">
            <section className="responsive-page-content">
                <header className="content-header row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <span className="vertical-text-decorator">
                            <span className="typo-s2">
                                {t('VerifymailSent.Headline')}
                            </span>
                            <h2>
                                {t('VerifymailSent.Title')}
                            </h2>
                        </span>
                    </div>
                </header>
                <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        {t('VerifymailSent.Text')}
                    </div>
                </main>
                <footer className="content-footer transparent-lg-style" />
            </section>
            <aside className="secondary-area" />
        </div>
    );
};