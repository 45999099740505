import { Instance, types } from 'mobx-state-tree';
import { IUnverifiedParams } from '../common/stores/viewStore/contracts';
import { unverifiedStoreActions } from './unverifiedStore.actions';

export const UnverifiedStore = types.model({
    token: types.optional(types.string, ''),
    error: types.optional(types.string, '')
}).actions((self: any) => unverifiedStoreActions(self));

export interface IUnverifiedStore extends Instance<typeof UnverifiedStore> { }

export type IUnverifiedStoreActions = {
    initializeForUnverifiedRoute: (params: IUnverifiedParams) => void;
    resendConfirmationMail: () => Promise<void>;
};