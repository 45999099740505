import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { IRegistrationModel } from '../store/commonRegistrationModel';
import { nameofFactory } from '../../common';
import { useStore } from '../../useStore';
import { ILabeledCheckboxChangeEvent, LabeledCheckbox } from '../../common/components/formControlls/labeledCheckbox';
import { GDPRTranslationSelector } from '../../consentManagement/selectGDPRLabel';

const I18NEXT_NAMESPACE = ['aiapp_register', 'aiapp_errors'];

export interface IConsentRegistration {
    commonRegistrationModel: IRegistrationModel;
}

export const ConsentSection = (props: IConsentRegistration) => {
    const [t, i18next] = useTranslation(I18NEXT_NAMESPACE);

    function updateGDPR(e: ILabeledCheckboxChangeEvent) {
        const names = nameofFactory<IRegistrationModel>();
        props.commonRegistrationModel.updateValue(names('isGDPRApproved'), e.value);
    }

    function updateNewsletter(e: ILabeledCheckboxChangeEvent) {
        const names = nameofFactory<IRegistrationModel>();
        props.commonRegistrationModel.updateValue(names('isNewsletterApproved'), e.value);
    }

    function localizeErrors(errors: string[]) {
        if (errors) {
            return errors.map((error: string) => t(`aiapp_errors:${error}`));
        }
        return [];
    }

    function renderGDPRErrorFeedback() {
        const errors = localizeErrors(props.commonRegistrationModel.gdprErrors);
        return (
            errors ? <div className="invalid-feedback d-block">{errors}</div> : null
        );
    }

    return useObserver(() => {
        const { viewStore } = useStore();
        function renderGDPRTextComponents() {
            const labels = new GDPRTranslationSelector({ i18next, appName: viewStore.auth0configurationTitle!, useFallback: true });
            return [
                <a target="_blank" key="0" href={labels.termsAndConditionsLink} rel="noreferrer">__</a>,
                <a target="_blank" key="0" href={labels.dataStorageTermsLink} rel="noreferrer">__</a>
            ];
        }

        return (
            <section className="consentAreaComponent">
                <div className="row">
                    <div className="col-12">
                        <LabeledCheckbox
                            className="mb-2"
                            checked={props.commonRegistrationModel.isGDPRApproved}
                            onChange={updateGDPR}
                            name={'updateGDPR'}
                        >
                            <Trans
                                ns={'aiapp_register'}
                                components={renderGDPRTextComponents()}
                            >
                                {t('GDPRRegisterUser')}
                            </Trans>
                        </LabeledCheckbox>
                        <label className="d-inline typo-c1" />
                        {renderGDPRErrorFeedback()}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <LabeledCheckbox
                            className="mb-3"
                            checked={props.commonRegistrationModel.isNewsletterApproved}
                            onChange={updateNewsletter}
                        >
                            <span className="d-inline typo-c1">
                                <Trans
                                    ns={'aiapp_register'}
                                    i18nKey="NewsletterConsent"
                                />
                            </span>
                        </LabeledCheckbox>
                    </div>
                </div>
            </section>
        );
    });
};
export default ConsentSection;