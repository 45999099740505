import { IPasswordresetRequestActions, IPasswordresetRequestModel, IPasswordresetRequestnapshotIn } from './passwordResetRequest.model';
import { ValidationError } from 'class-validator';
import { SnapshotIn, getParent, castToSnapshot, flow } from 'mobx-state-tree';
import { ErrorModel, ErrorsModel } from '../../common/stores/errorModel';
import { PasswordresetRequestValidator } from './PasswordresetRequestValidator';
import { IPasswordResetStoreModel } from './passwordResetStore.model';

export type ValidationProps = Required<Pick<IPasswordresetRequestnapshotIn, 'email' | 'password' | 'passwordrepeat'>>;

export function mapToErrorModel(validationError: ValidationError[]): SnapshotIn<typeof ErrorsModel> {
    const ret: SnapshotIn<typeof ErrorsModel> = { entrys: [] };
    for (const item of validationError) {
        if (item.constraints) {
            const error: SnapshotIn<typeof ErrorModel> = { field: item.property };
            error.errors = Object.values(item.constraints).reverse();
            ret.entrys!.push(error);
        }
    }
    return ret;
}

export const passwordResetRequestActions = (self: IPasswordresetRequestModel): IPasswordresetRequestActions => {
    return {
        update: (update: IPasswordresetRequestnapshotIn) => {
            Object.assign(self, update);
        },
        validate: () => {
            const validator = new PasswordresetRequestValidator(self);
            self.errors = castToSnapshot(validator.validate());
            return self.errors.hasErrors;
        },
        validateSingle: (name) => {
            const validator = new PasswordresetRequestValidator(self);
            const validationResult = validator.validate();

            const errorObj = self.errors.entrys.find((x) => x.field === name.toString());
            if (errorObj) {
                self.errors.entrys.remove(errorObj);
            }

            const error = validationResult.entrys?.find((x) => x.field === name.toString());
            if (error) {
                self.errors.entrys.push(error);
                console.log(self.errors.entrys.toJSON());
            }
        },
        validateAndSave: flow(function * () {
            self.validate();
            self.isSaved = false;
            if (self.errors.hasErrors === false) {
                const parent = getParent<IPasswordResetStoreModel>(self);
                const saved = yield parent.saveChangeRequest();
                self.isSaved = saved;
            }
            return self.isSaved;
        })
    };
};