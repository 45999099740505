import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import { useObserver } from 'mobx-react';
import Input from '../../common/components/input';
import { useStore } from '../../useStore';
import Button from '../../common/components/button';

import './login.scss';
import { LocalizedErrorFeedback } from '../../common/components/localizedErrorFeedback';

interface ILoginComponentProps {
  className?: string;
}

const I18NEXT_NAMESPACE = ['aiapp_login', 'aiapp_errors'];

const Login = (props: ILoginComponentProps) => {
    const [t] = useTranslation(I18NEXT_NAMESPACE);
    const { loginStore, viewStore } = useStore();
    function login(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        try {
            loginStore.login();
        } catch { } // tslint:disable-line: no-empty
    }

    function updateValue(elementName: any, newValue: string) {
        if (elementName === 'email') {
            loginStore.updateEmail(newValue);
        } else if (elementName === 'password') {
            loginStore.updatePassword(newValue);
        }
    }

    function checkAuthenticationRedirect() {
        try {
            loginStore.loginfed();
        } catch { } // tslint:disable-line: no-empty
    }

    function localizeErrors(errors: string[]) {
        if (errors) {
            return errors.map((error: string) => t(`aiapp_errors:${error}`));
        }

        return [];
    }

    function renderDefaultPasswordReset() {
        return (<Button style="link" className="passwordRecoveryLink -secondary-text" onClick={() => loginStore.showPasswordReset()}>
            <Trans ns={I18NEXT_NAMESPACE}>PasswordRecovery</Trans><i className="ml-1 icon-icon_control_arrow_right" />
        </Button>);
    }

    function showRegister() {
        viewStore.showRegister();
    }

    function renderRegisterButton() {
        return (
            <button
                type="button"
                onClick={showRegister}
                className="btn btn-link btn-reg-switch mb-3"
            >
                <span>__</span>
            </button>
        );
    }

    function renderErrorFeedbackSection() {
        return (<section className="feedbackArea">
            {loginStore.commonErrors.map((e, i) => <LocalizedErrorFeedback key={i} errorKey={e} />)}
        </section>);
    }

    return useObserver(() => {
        const RegistrationFragment = () => <span className="page-switch-link">
            <Trans
                ns={I18NEXT_NAMESPACE}
                i18nKey="Registertext"
                components={[renderRegisterButton()]}
            />
        </span>;

        const appNameText = t([`AppName_${viewStore.auth0configurationTitle!}`, `AppName_${viewStore.auth0configurationClientId!}`]);
        return (
            <section className={`${props.className || ''} loginForm ${loginStore.isEmpty ? 'empty' : ''}`}>
                <header className="content-header row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <span className="vertical-heading-decorator">
                            <span className="typo-s2"><Trans ns={I18NEXT_NAMESPACE}>TitleHeadline</Trans> </span>
                            <h2 className="typo-h2"><Trans ns={I18NEXT_NAMESPACE}>Title</Trans></h2>
                        </span>

                        <p className="typo-c1">{t('LogintextV2', { appname: appNameText })}</p>
                        <p className="typo-c1-bold">
                            <Trans ns={I18NEXT_NAMESPACE}>Motivationtext</Trans>
                        </p>
                    </div>
                </header>
                <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        {loginStore.hasErrors && renderErrorFeedbackSection()}

                        <Form onSubmit={login} noValidate={true} method="post">
                            <div className="row">
                                <div className="col-12">
                                    <Input
                                        id="login_email_input"
                                        name="email"
                                        type="email"
                                        placeholder={t('Email') as string}
                                        value={loginStore.email}
                                        errorMessage={localizeErrors(loginStore.emailErrors)}
                                        onChange={updateValue}
                                        onBlur={checkAuthenticationRedirect}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Input
                                        id="login_password_input"
                                        name="password"
                                        type="password"
                                        placeholder={t('Password')}
                                        value={loginStore.password}
                                        errorMessage={localizeErrors(loginStore.passwordErrors)}
                                        onChange={updateValue}
                                    />
                                    {renderDefaultPasswordReset()}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn btn-danger -btn-login w-100 text-uppercase"
                                    >
                                        <Trans ns={I18NEXT_NAMESPACE}>Login</Trans>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </main>
                <footer className="content-footer transparent-lg-style">
                    {viewStore.features.registrationEnabled && <RegistrationFragment />}
                </footer>
            </section>
        );
    });
};

export default Login;