export type evalType = { condition: boolean, className: string };
export const className = (...classes: Array<string | evalType | undefined | null>): string => {
    const stringsOnly = classes.map((x) => {
        const y = x as evalType;
        if (typeof y !== 'undefined' && typeof y.condition !== 'undefined') {
            if (y.condition === true) {
                return y.className;
            }
        } else {
            return x as string | undefined | null;
        }
        return undefined;
    });
    return stringsOnly.filter((x) => x !== undefined && x !== null).join(' ');
};