import { useObserver } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'reactstrap/lib/Alert';
import InputGroup from '../common/components/input';
import { useStore } from '../useStore';
import { IPasswordresetRequestnapshotIn } from './store/passwordResetRequest.model';
import { I18nAreaNamespace } from './index';
import bindTrans from '../common/components/bindTrans';

import './passwordResetPage.scss';

const I18nNamespace = I18nAreaNamespace;

export const CommonErrorsPartial: React.FunctionComponent = () => {
    const { t } = useTranslation(I18nNamespace);
    const { passwordResetStore } = useStore();
    return useObserver(() => {
        return <>{(passwordResetStore.commonErrors).map((e: string) => <Alert color="danger" key={e} >{t('Errors.' + e)}</Alert>)}</>;
    });
};

export const FormView: React.FunctionComponent = () => {
    const { t } = useTranslation(I18nNamespace);
    const { passwordResetStore } = useStore();
    const passwordResetRequest = passwordResetStore.passwordResetRequest;

    return useObserver(() => {
        const updateValue = <T extends keyof IPasswordresetRequestnapshotIn>(elementName: T, newValue: string) => {
            const update = {};
            update[elementName as string] = newValue;
            passwordResetRequest.update(update);
        };

        const save = () => {
            passwordResetRequest.validateAndSave();
        };

        const onBlur = <T extends keyof IPasswordresetRequestnapshotIn>(name: T) => {
            passwordResetRequest.validateSingle(name);
        };

        return <>
            <div className="col-lg-10 col-xl-8">
                <p className="typo-c1">{passwordResetRequest.isEMailReadonly ? '' : t('PasswordReset.Infotext')}</p>
                <InputGroup
                    type="email"
                    name="email"
                    readOnly={passwordResetRequest.isEMailReadonly}
                    value={passwordResetRequest.email}
                    onChange={(name, value) => updateValue('email', value)}
                    placeholder={t('Form.Email')}
                    errorMessage={(passwordResetRequest.errors.findByFieldName<IPasswordresetRequestnapshotIn>('email')).map((v: string) => t(v))}
                    onBlur={(name) => onBlur('email')}
                />
                <InputGroup
                    type="password"
                    name="password"
                    readOnly={false}
                    value={passwordResetRequest.password}
                    onChange={(name, value) => updateValue('password', value)}
                    placeholder={t('Form.Password')}
                    errorMessage={(passwordResetRequest.errors.findByFieldName<IPasswordresetRequestnapshotIn>('password')).map((v: string) => t(v))}
                    onBlur={(name) => onBlur('password')}
                />
                <InputGroup
                    type="password"
                    name="passwordrepeat"
                    readOnly={false}
                    value={passwordResetRequest.passwordrepeat}
                    onChange={(name, value) => updateValue('passwordrepeat', value)}
                    placeholder={t('Form.Passwordrepeat')}
                    errorMessage={(passwordResetRequest.errors.findByFieldName<IPasswordresetRequestnapshotIn>('passwordrepeat')).map((v: string) => t(v))}
                    onBlur={(name) => onBlur('passwordrepeat')}
                />
                <CommonErrorsPartial />
            </div>
            <div className="col-lg-6 p-1">
                <button type="button" onClick={() => save()} className="btn btn-secondary -btn-save w-100">
                    {t('Form.Save')}
                </button>
            </div>
        </>;
    });
};

export const SentView: React.FunctionComponent = () => {
    const BoundTrans = bindTrans(I18nNamespace);
    return useObserver(() => {
        return <>
            <div className="col-lg-10 col-xl-8">
                <span className="typo-c1">
                    <BoundTrans>
                        PasswordReset.Requestsentinfotext
                    </BoundTrans>
                </span>
            </div>
        </>;
    });
};

export const PasswordResetPage = () => {
    const { t } = useTranslation(I18nNamespace);
    const { passwordResetStore: { passwordResetRequest } } = useStore();

    return useObserver(() => {
        return (
            <div className="passwordResetPage responsive-page-grid">
                <section className="responsive-page-content">
                    <header className="content-header row no-gutters justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <span className="vertical-heading-decorator">
                                <span className="typo-s2">{t('PasswordReset.Titleheadline')}</span>
                                <h2 >{t('PasswordReset.Title')}</h2>
                            </span>
                        </div>
                    </header>
                    <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                        {passwordResetRequest.isSaved === false ? <FormView /> : <SentView />}
                    </main>
                    <footer className="content-footer transparent-lg-style" />
                </section>
                <aside className="secondary-area" />
            </div>);
    });
};