import { Auth0Service } from '../../common/services/auth0Service/auth0Service';
import { Auth0ErrorTypes, RegistrationTypes } from '../../common/services/auth0Service/contracts';
import { ValidatonService } from '../../common/validation/validatonService';
import createUserMetadataParam from './createUserMetadataParam';
import { IRegisterStore } from './registerStoreModel';
import { getEnv, getRoot } from '../../common/utils/mobx';
import { CommonRegistrationValidationModel } from './verification/CommonRegistrationValidationModel';

async function sendRegistrationData(self: IRegisterStore) {
    const { authZeroConfig, i18n } = getEnv(self);
    const auth0Service = new Auth0Service(authZeroConfig);
    const rs = getRoot(self);
    const unlockFn = rs.viewStore.lockUI();
    try {
        const registrationdata = createUserMetadataParam(
            self,
            rs.viewStore.auth0configurationClientId!
        );

        await auth0Service.register(self.commonRegistrationModel.email, self.commonRegistrationModel.password, registrationdata, i18n.language.substr(0, 2));
        return;
    } catch (errorcode) {
        self.setRegistrationError(errorcode);
        return;
    } finally {
        unlockFn();
    }
}

async function registerAsConsumer(self: IRegisterStore): Promise<boolean> {
    const isValid = self.validateCommonModel();
    if (isValid) {
        await sendRegistrationData(self);
    }
    return isValid;
}

async function registerAsCustomer(self: IRegisterStore): Promise<boolean> {
    const isValid = self.validateCustomerRegistration();
    if (isValid) {
        sendRegistrationData(self);
    }
    return isValid;
}

export const register = (self: IRegisterStore): () => Promise<boolean> => {
    return async () => {
        return self.isConsumerRegistration
            ? registerAsConsumer(self)
            : registerAsCustomer(self);
    };
};

export const validateCustomerRegistration = (self: IRegisterStore): () => boolean => {
    return () => {
        const isCommonValid = self.validateCommonModel();
        const isCustomerValid = self.customerRegistrationModel.validate();
        const requiresCompanyProfile = !self.customerRegistrationModel.customerWithCustomerID;
        const companyProfileIsValid = requiresCompanyProfile ? self.companyProfileModel.validate() : true;
        return isCommonValid && isCustomerValid && companyProfileIsValid;
    };
};

export const validatePasswordWasRepeated = (self: IRegisterStore): () => boolean => {
    return (): boolean => {
        const isValid = self.commonRegistrationModel.isConfirmed;
        self.commonRegistrationModel.passwordConfirmationErrors.replace(self.commonRegistrationModel.isConfirmed ? [] : ['password_confirmation_error']);
        return isValid;
    };
};

export const clearBasicRegistrationErrors = (self: IRegisterStore): () => void => {
    return () => {
        self.commonRegistrationModel.emailErrors.replace([]);
        self.commonRegistrationModel.commonErrors.replace([]);
        self.commonRegistrationModel.passwordErrors.replace([]);
        self.customerRegistrationModel.clearErrors();
    };
};

export const validateCommonModel = (self: IRegisterStore): () => boolean => {
    return (): boolean => {
        const validationModel = new CommonRegistrationValidationModel();
        validationModel.email = self.commonRegistrationModel.email;
        validationModel.isConfirmed = self.commonRegistrationModel.isConfirmed;
        validationModel.isGDPRApproved = self.commonRegistrationModel.isGDPRApproved;
        validationModel.password = self.commonRegistrationModel.password;
        validationModel.firstName = self.commonRegistrationModel.firstName;
        validationModel.lastName = self.commonRegistrationModel.lastName;

        const errors = new ValidatonService().validate(validationModel);
        self.commonRegistrationModel.emailErrors.replace(errors === null ? [] : errors.email);
        self.commonRegistrationModel.passwordErrors.replace(errors === null ? [] : errors.password);
        self.commonRegistrationModel.gdprErrors.replace(errors === null ? [] : errors.isGDPRApproved);
        self.commonRegistrationModel.firstNameErrors.replace(errors === null ? [] : errors.firstName);
        self.commonRegistrationModel.lastNameErrors.replace(errors === null ? [] : errors.lastName);
        const isValid = self.validatePasswordWasRepeated();
        return !errors && isValid;
    };
};

export const setRegistrationError = (self: IRegisterStore): (errorcode: string) => void => {
    return (errorcode: string) => {
        const env = getEnv(self);
        const auth0Service = new Auth0Service(env.authZeroConfig);
        self.clearBasicRegistrationErrors();
        const target = auth0Service.getAuth0ErrorType(errorcode);
        if (target === Auth0ErrorTypes.emailRelated) {
            self.commonRegistrationModel.emailErrors.replace([errorcode]);
        } else if (target === Auth0ErrorTypes.passwordRelated) {
            self.commonRegistrationModel.passwordErrors.replace([errorcode]);
        } else {
            self.commonRegistrationModel.commonErrors.replace([errorcode]);
        }
    };
};

export const changeRegistrationType = (self: IRegisterStore): (registrationType: RegistrationTypes) => void => {
    return (registrationType: RegistrationTypes) => {
        self.registrationType = registrationType;
    };
};