import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import * as actions from './commonRegistrationActions';

const RegistrationModel = types.model({
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    isConfirmed: types.optional(types.boolean, false),
    isGDPRApproved: types.optional(types.boolean, false),
    isNewsletterApproved: types.optional(types.boolean, false),
    errors: types.optional(types.array(types.string), []),
    commonErrors: types.optional(types.array(types.string), []),
    passwordErrors: types.optional(types.array(types.string), []),
    passwordConfirmationErrors: types.optional(types.array(types.string), []),
    emailErrors: types.optional(types.array(types.string), []),
    gdprErrors: types.optional(types.array(types.string), []),
    firstNameErrors: types.optional(types.array(types.string), []),
    lastNameErrors: types.optional(types.array(types.string), [])
}).views((self) => ({
    get allErrors() {
        const errors: string[] = [];
        errors.push(...(self.errors || []));
        errors.push(...(self.emailErrors || []));
        errors.push(...(self.commonErrors || []));
        errors.push(...(self.passwordErrors || []));
        errors.push(...(self.passwordConfirmationErrors || []));
        errors.push(...(self.gdprErrors || []));
        errors.push(...(self.firstNameErrors || []));
        errors.push(...(self.lastNameErrors || []));

        return errors;
    }
})).actions((self: any) => actions.commonRegistrationActions(self) as IRegistrationModelActions);
export interface IRegistrationModel extends Instance<typeof RegistrationModel> { }
export interface IRegistrationSnapshotOut extends SnapshotOut<typeof RegistrationModel> { }
export interface IRegistrationSnapshotIn extends SnapshotOut<typeof RegistrationModel> { }

export type IRegistrationModelActions = {
  updateValue: <T extends keyof IRegistrationModel>(elementName: keyof IRegistrationModel, newValue: IRegistrationModel[T]) => void;
};

export { RegistrationModel };