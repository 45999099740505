import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { ErrorModel } from '../../common/stores/errorModel';
import { companyProfileActions } from './companyProfileActions';

export const CompanyProfileModel = types.model({
    companyName: types.optional(types.string, ''),
    street: types.optional(types.string, ''),
    houseNumber: types.optional(types.string, ''),
    taxId: types.optional(types.string, ''),
    city: types.optional(types.string, ''),
    postalCode: types.optional(types.string, ''),
    country: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    errors: types.optional(types.array(ErrorModel), [])
}).actions((self: any) => companyProfileActions(self));

export interface ICompanyProfileModel extends Instance<typeof CompanyProfileModel> { }
export interface ICompanyProfileSnapshotOut extends SnapshotOut<typeof CompanyProfileModel> { }
export interface ICompanyProfileSnapshotIn extends SnapshotIn<typeof CompanyProfileModel> { }

export type IExtendedRegistrationModelActions = {
    updateValue: <T extends keyof ICompanyProfileSnapshotIn>(elementName: keyof ICompanyProfileSnapshotIn, newValue: ICompanyProfileSnapshotIn[T]) => void;
    validate: () => boolean;
    clearErrors: () => void;
};