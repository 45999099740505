import * as React from 'react';
import RegisterNavigation from './registerNavigation';
import { useObserver } from 'mobx-react';

import './registerPage.scss';

const RegisterPage = () => {
    return useObserver(() => {
        return (
            <div className="registerPage responsive-page-grid">
                <RegisterNavigation className="responsive-page-content" />
                <aside className="secondary-area" />
            </div>
        );
    });
};

export default RegisterPage;