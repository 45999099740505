export class LangRessourcesService {
    public static readonly defaultNamespace = 'aiapp_default';
    
    public get languages(): string[] {
        return ['en', 'de'];
    }

    public get namespaces(): string[] {
        return [
            LangRessourcesService.defaultNamespace,
            'aiapp_register',
            'aiapp_login',
            'aiapp_errors',
            'aiapp_accountmanagement'];
    }
}