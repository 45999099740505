import { ILogger } from './ILogger';

/** backend - frontend bridge for winston logger
 * nodejs applications should configure a default logger when application starts up
 */
export class DefaultLogger {
    private static defaultInstance: ILogger | undefined;
    private static enabled = false;
    public static loglevel: string;

    public static configure(enabled: boolean, loglevel: string, loggerInstance?: ILogger) {
        DefaultLogger.enabled = enabled;
        DefaultLogger.loglevel = loglevel;
        DefaultLogger.defaultInstance = loggerInstance;
    }

    constructor(private instance?: ILogger) {
    }

    public error(message: string, ...meta: any[]) {
        this.loginternal('error', message, ...meta);
    }

    public warn(message: string, ...meta: any[]) {
        this.loginternal('warn', message, ...meta);
    }

    public info(message: string, ...meta: any[]) {
        this.loginternal('info', message, ...meta);
    }

    public http(message: string, ...meta: any[]) {
        this.loginternal('http', message, ...meta);
    }

    public debug(message: string, ...meta: any[]) {
        this.loginternal('debug', message, ...meta);
    }

    public verbose(message: string, ...meta: any[]) {
        this.loginternal('verbose', message, ...meta);
    }

    public level(message: string, ...meta: any[]) {
        this.loginternal('level', message, ...meta);
    }

    public trace(message: string, ...meta: any[]) {
        this.loginternal('trace', message, ...meta);
    }

    public fatal(message: string, ...meta: any[]) {
        this.loginternal('error', message, ...meta);
    }

    public log(message: string, trace: any) {
        this.loginternal('info', message + ' ' + trace);
    }

    public loglevel(level: string, message: string) {
        this.loginternal(level, message);
    }

    private loginternal(loglevel: string, message: string, ...meta: any[]) {
        if (DefaultLogger.enabled) {
            const logger = this.instance || DefaultLogger.defaultInstance;
            if (logger) {
                logger.log(loglevel, message, meta);
            } else {
                console.log('[console] ' + message, meta);
            }
        }
    }
}

export const defaultLogger = new DefaultLogger();
