import { arrayNotEmpty, isEmpty } from 'class-validator';
import { Instance, types } from 'mobx-state-tree';
import { loginStoreActions } from './loginStoreActions';

export const LoginStore = types.model({
    email: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    errors: types.optional(types.array(types.string), []),
    commonErrors: types.optional(types.array(types.string), []),
    passwordErrors: types.optional(types.array(types.string), []),
    emailErrors: types.optional(types.array(types.string), [])
}).views((self) => ({
    get isEmpty(): boolean {
        return isEmpty(self.email) &&
      isEmpty(self.password);
    },
    get hasErrors(): boolean {
        return arrayNotEmpty(self.commonErrors) ||
      arrayNotEmpty(self.passwordErrors) ||
      arrayNotEmpty(self.emailErrors);
    }
})).actions((self: any) => loginStoreActions(self));

export interface ILoginStore extends Instance<typeof LoginStore> { }
export type ILoginStoreActions = {
  clearErrors: () => void;
  setError: (errorcode: string) => void;
  updateEmail: (value: string) => void;
  updatePassword: (value: string) => void;
  login: () => void;
  loginfed: () => void;
  showPasswordReset: () => void;
};
