import { IPasswordResetCallbackRouteParams, IPasswordResetRouteParams } from '../../common/stores/viewStore/contracts';
import { getEnv, getRoot } from '../../common/utils/mobx';
import { IPasswordResetStoreActions, IPasswordResetStoreModel } from './passwordResetStore.model';
import PasswordResetRepository from './passwordResetRepository';

export const passwordResetStoreActions = (self: IPasswordResetStoreModel): IPasswordResetStoreActions => {
    return {
        initForPasswordCallbackRoute: (routeParams: IPasswordResetCallbackRouteParams) => {
            self.changeCompletedModel.changeReceived = routeParams.success !== undefined;
            self.changeCompletedModel.changeSuccessful = routeParams.success === 'true';
        },
        initForPasswordResetRoute: (routeParams: IPasswordResetRouteParams) => {
            const { i18n } = getEnv(self);
            self.passwordResetRequest.errors.entrys.clear();
            self.passwordResetRequest.isEMailReadonly = !!(routeParams.email && routeParams.email.length > 0);
            self.passwordResetRequest.email = routeParams.email || '';
            self.passwordResetRequest.appId = routeParams.appId || '';
            self.passwordResetRequest.language = i18n.language.substr(0, 2);
            self.passwordResetRequest.isSaved = false;
        },
        startDataProcessing: () => {
            self.commonErrors.clear();
        },
        stopDataProcessing: (erros?: string[]) => {
            self.commonErrors.replace(erros || []);
        },
        saveChangeRequest: async () => {
            const root = getRoot(self);

            const unlock = root.viewStore.lockUI();

            try {
                self.startDataProcessing();
                await (new PasswordResetRepository().changePassword(
                    self.passwordResetRequest.email,
                    self.passwordResetRequest.password,
                    self.passwordResetRequest.language,
                    self.passwordResetRequest.appId
                ));
                self.stopDataProcessing();
                return true;
            } catch (e) {
                self.stopDataProcessing([(e as Error).message]);
            } finally {
                unlock();
            }

            return false;
        }
    };
};