import * as BackendProxy from 'abus-cloud-access-identity-backend';
import { bffBaseUrl } from './bffBaseUrl';

export abstract class BaseRepository {
    protected bffProxyConfiguration: BackendProxy.Configuration;

    constructor() {
        this.bffProxyConfiguration = new BackendProxy.Configuration();
        this.bffProxyConfiguration.basePath = bffBaseUrl();
    }
}