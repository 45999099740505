import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import InputGroup from '../../common/components/input';
import { InputType } from 'reactstrap/lib/Input';
import Dropdown, { IItem } from '../../common/components/dropdown';
import { Alert } from 'reactstrap';
import { ICompanyProfileModel } from '../store/companyProfileModel';
import { Instance } from 'mobx-state-tree';
import { useStore } from '../../useStore';
import { ErrorModel } from '../../common/stores/errorModel';

interface IProfileEditorProps {
    className?: string;
}

const I18NEXT_NAMESPACE = ['aiapp_register', 'aiapp_errors'];

const ProfileForm = (props: IProfileEditorProps) => {
    const [t] = useTranslation(I18NEXT_NAMESPACE);
    const { viewStore, registerStore } = useStore();

    return useObserver(() => {
        function localizeModelErrors(errors: Array<Instance<typeof ErrorModel>>, fieldName: string) {
            if (errors) {
                const error = errors.find((x) => x.field === fieldName);
                const errorkeys = error?.errors;
                if (errorkeys) {
                    return errorkeys.map((e: string) => t(e));
                }
            }

            return [];
        }

        function updateCompanyProfile(elementName: any, newValue: string) {
            registerStore.companyProfileModel.updateValue(
                elementName,
                newValue
            );
        }

        function renderRegisterButton() {
            return (
                <button
                    className="btn btn-danger -btn-register w-100"
                    name="register"
                    onClick={() => { registerStore.register() }}
                >
                    <Trans ns={I18NEXT_NAMESPACE}>Register</Trans>
                </button>);
        }

        function renderLoginButton() {
            return (
                <button
                    key="0"
                    type="button"
                    onClick={() => viewStore.showLogin()}
                    className="btn btn-link btn-reg-switch"
                >
                    <span>__</span>
                </button>
            );
        }

        function renderHeader() {
            return (<header className="content-header paper-background row no-gutters justify-content-center">
                <div className="col-12">
                    <div className="position-relative rpg-pullout">
                        <button className="btn back btn-link p-0" onClick={() => viewStore.showRegisterCustomer()}>
                            <i className="icon-Row_arrow_left" /><Trans ns={I18NEXT_NAMESPACE}>default:Back</Trans>
                        </button>
                    </div>
                    <h6 className="mb-3 mb-lg-4"><Trans ns={I18NEXT_NAMESPACE}>Profile.CustomerProfileTitle</Trans></h6>
                </div>
            </header>);
        }

        function renderFooter() {
            return (<footer className="content-footer secondary-style pt-5 no-gutters align-items-end">
                <div className="row col-12 col-sm-6 pr-lg-2 mb-lg-0">
                    {renderRegisterButton()}
                </div>
                <div className="row col-12 col-sm-6 pt-5 pt-sm-0 pl-lg-2 mt-3">
                    <span className="page-switch-link">
                        <Trans
                            ns={I18NEXT_NAMESPACE}
                            i18nKey="Logintext"
                            components={[renderLoginButton()]}
                        />
                    </span>
                </div>
            </footer>);
        }

        function renderCountryDropDown(className: string) {
            const items: IItem[] = [
                { key: 'DE', text: t('default:Countrys.Germany') },
                { key: 'AT', text: t('default:Countrys.Austria') },
                { key: 'CH', text: t('default:Countrys.Switzerland') }
            ];

            return (
                <Dropdown
                    className={className}
                    items={items}
                    id="country"
                    name="country"
                    onChange={updateCompanyProfile}
                    placeholder={t('Profile.Country')}
                    errorMessage={localizeModelErrors(registerStore.companyProfileModel.errors, 'country')}
                    value={registerStore.companyProfileModel.country}
                />
            );
        }

        function renderInput<K extends keyof ICompanyProfileModel>(
            key: K,
            placeholderLabelKey: string,
            opts?: { type?: InputType, helpLabelKey?: string, readOnly?: boolean, className?: string }
        ): any {
            const options = opts || {};
            options.type = options.type || 'text';
            const model = registerStore.companyProfileModel;
            const value: any = model != null ? model[key] : '';

            return (
                <InputGroup
                    errorMessage={localizeModelErrors(registerStore.companyProfileModel.errors, key as string)}
                    className={options.className}
                    type={options.type as InputType}
                    id={key as string}
                    name={key as string}
                    placeholder={t(placeholderLabelKey) as string}
                    value={value}
                    help={options.helpLabelKey ? t(options.helpLabelKey) as string : undefined}
                    readOnly={options.readOnly}
                    onChange={updateCompanyProfile}
                />
            );
        }

        function renderMain() {
            const arrayErrorMap = (text: string) => {
                return (<Alert key={text} color="danger"><Trans ns={I18NEXT_NAMESPACE}>errors:{text}</Trans></Alert>);
            };
            const values = Object.values(registerStore.customerRegistrationModel.errors);
            values.forEach((i: any) => {
                if (i) {
                    i.map(arrayErrorMap);
                }
            });

            return (<main className="content-main pt-4 secondary-style row no-gutters">
                <div className="col-12 row ">
                    <p className="col-12 horizonal-heading-decorator order-md-0 rpg-pullout mt-2">
                        <Trans ns={I18NEXT_NAMESPACE}>Profile.CompanyDataHeadline</Trans>
                    </p>
                    {renderInput('companyName', 'Profile.CompanyName', { type: 'text', className: 'col-12 col-md-6 order-md-0' })}
                    {renderInput('street', 'Profile.Street', { type: 'text', className: 'col-9 col-md-4 order-md-0' })}
                    {renderInput('houseNumber', 'Profile.HouseNumber', { type: 'text', className: 'col-3 col-md-2 order-md-0' })}
                    {renderInput('city', 'Profile.City', { type: 'text', className: 'col-12 col-md-4 order-md-2' })}
                    {renderInput('postalCode', 'Profile.PostalCode', { type: 'text', className: 'col-12 col-md-2 order-md-2' })}
                    {renderCountryDropDown('col-12 col-md-6 order-md-1')}
                    {renderInput('phone', 'Profile.Phone', { type: 'text', className: 'col-12 col-md-6 order-md-4' })}
                    {renderInput('taxId', 'Profile.TaxId', { type: 'text', className: 'col-12 col-md-6 order-md-3' })}
                </div>
                <div className="row no-gutters">
                    <div className="col-12">
                        {registerStore.commonRegistrationModel.allErrors.map(arrayErrorMap)}
                        {registerStore.customerRegistrationModel.flatErrors.map(arrayErrorMap)}
                    </div></div>
            </main>);
        }

        return (<div className={`${props.className || ''}`}>
            {renderHeader()}
            {renderMain()}
            {renderFooter()}
        </div>);
    });
};

export default ProfileForm;