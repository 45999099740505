import * as React from 'react';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import './imprint.scss';

class Imprint extends React.Component<WithTranslation, any> {
    public render() {
        const { t } = this.props;
        return (
            <div className="imprint">
                <div className="container imprint-mwidth pt-5">
                    <section className="">
                        <h2 className="typo-h">{t('Imprint.Heading')}</h2>
                        <p className="typo-c1" ><Trans {...this.props}>Imprint.CompanyAddress</Trans></p>

                        <p className="m-0 typo-c1">{t('Imprint.Phone.Abbreviation')}
                            <a type="tel" href={`tel:${t('Imprint.Phone.Link')}`}>
                                {t('Imprint.Phone.Display')}
                            </a>
                        </p>
                        <p className="m-0 typo-c1">{t('Imprint.Fax.Abbreviation')}
                            <a type="fax" href={`fax:${t('Imprint.Fax.Link')}`}>
                                {t('Imprint.Fax.Display')}
                            </a>
                        </p>
                        <p className="m-0 typo-c1 ">
                            <a href={t('Imprint.Web')}>{t('Imprint.Web')}</a>
                        </p>
                        <p className="mt-0 typo-c1 ">
                            <a href={`email:${t('Imprint.Email')}`} >{t('Imprint.Email')}</a>
                        </p>
                        <p className="typo-c1"><Trans {...this.props}>Imprint.CompanyJurification</Trans></p>
                    </section>
                    <section className="pt-3">
                        <h3 className="typo-h4">{t('LiabilityNotice.Heading')}</h3>
                        <p className="typo-c1">{t('LiabilityNotice.Text')}</p>
                    </section>
                    <section className="pb-5">
                        <h2 className="typo-h3">{t('Disclaimer')}</h2>
                        <h3 className="typo-h4">{t('Usage.Heading')}</h3>
                        <p className="typo-c1" >{t('Usage.Text')}</p>
                        <h3 className="typo-h4">{t('Links.Heading')}</h3>
                        <p className="typo-c1">{t('Links.Text')}</p>
                        <h3 className="typo-h4">{t('Applicability.Heading')}</h3>
                        <p className="typo-c1">{t('Applicability.Text')}</p>
                        <h3 className="typo-h4">{t('OnlineDispute.Heading')}</h3>
                        <p className="typo-c1"><Trans {...this.props}>OnlineDispute.Text</Trans></p>
                    </section>
                </div>
            </div>
        );
    }
}

export default withTranslation('aiapp_default')(Imprint);