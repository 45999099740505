import { types } from 'mobx-state-tree';
import { flatten } from '../utils/flatten';

export const ErrorModel = types.model({
    field: types.string,
    errors: types.optional(types.array(types.string), [])
});

export const ErrorsModel = types.model({
    entrys: types.array(ErrorModel)
}).views((self) => ({
    findByFieldName<T>(field: keyof T): string[] {
        const errorModel = self.entrys.find((x) => x.field === field);
        return errorModel?.errors || [];
    },
    get allErrors(): string[] {
        const errors: string[][] = self.entrys.map((x) => x.errors);
        return flatten(errors);
    }

})).views((self) => ({
    get hasErrors(): boolean {
        return self.allErrors.length !== 0;
    }
}));