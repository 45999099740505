import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import Input from '../../common/components/input';
import { ErrorFeedbackLine } from '../../common/components/feedback';
import { PasswordRepeat } from '../../common/components/passwordRepeat';
import { IRegistrationModel } from '../store/commonRegistrationModel';
import { nameofFactory } from '../../common';
import { arrayNotEmpty } from 'class-validator';

export interface IEmailPasswordRegistrationProps {
    emailPlaceholderKey: string;
    commonRegistrationModel: IRegistrationModel;
}

const I18NEXT_NAMESPACE = ['aiapp_register', 'aiapp_errors'];

const EmailPasswordSection = (props: IEmailPasswordRegistrationProps) => {
    const [t] = useTranslation(I18NEXT_NAMESPACE);

    function updateEmail(elementName: any, newValue: string) {
        if (elementName === 'email') {
            props.commonRegistrationModel.updateValue(elementName, newValue);
        }
    }

    function updatePassword(password: string, isConfirmed: boolean) {
        const names = nameofFactory<IRegistrationModel>();
        props.commonRegistrationModel.updateValue(names('password'), password);
        props.commonRegistrationModel.updateValue(names('isConfirmed'), isConfirmed);
    }

    function localizeErrors(errors: string[]) {
        if (errors) {
            return errors.map((error: string) => t(`aiapp_errors:${error}`));
        }

        return [];
    }
    function renderCommonErrors(item: string) {
        return (
            <ErrorFeedbackLine key={item}>
                <Trans ns={I18NEXT_NAMESPACE}>{item}</Trans>
            </ErrorFeedbackLine>
        );
    }

    function renderErrorFeedbackSection() {
        return (<section className="feedbackArea">
            {props.commonRegistrationModel.commonErrors.map(renderCommonErrors)}
        </section>);
    }

    function hasErrors4Display(): boolean {
        return arrayNotEmpty(props.commonRegistrationModel.allErrors);
    }

    return useObserver(() => {
        return (
            <section className="emailPasswordRegistrationComponent">
                {hasErrors4Display() && renderErrorFeedbackSection()}
                <div className="row">
                    <div className="col-12">
                        <Input
                            name="email"
                            type="email"
                            placeholder={t(props.emailPlaceholderKey)}
                            value={props.commonRegistrationModel.email}
                            errorMessage={localizeErrors(props.commonRegistrationModel.emailErrors)}
                            onChange={updateEmail}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <PasswordRepeat
                            passwordPlaceholder={t('EnterPassword')}
                            passwordConfirmationPlaceholder={t('RepeatPassword')}
                            passwordErrorMessages={localizeErrors(props.commonRegistrationModel.passwordErrors)}
                            passwordConfiramtionErrorMessages={localizeErrors(props.commonRegistrationModel.passwordConfirmationErrors)}
                            onChange={updatePassword}
                        />
                    </div>
                </div>
            </section>
        );
    });
};
export default EmailPasswordSection;