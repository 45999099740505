import * as React from 'react';
import './dropdown.scss';
import {
    Dropdown as RSDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    FormGroup,
    FormFeedback
} from 'reactstrap';

export interface IDropdownProps {
  placeholder?: string;
  id?: string;
  name?: string;
  errorMessage?: string[] | null;
  value?: string;
  items: IItem[];
  maxLength?: number;
  className?: string;
  onChange?: (name: string, value: string) => void;
}

export interface IItem {
  key: string;
  text: string;
}

export interface IDropdownState {
  dropdownOpen: boolean;
}

export default class Dropdown extends React.Component<
  IDropdownProps,
  IDropdownState
> {
    public getInitialState(): IDropdownState {
        return { dropdownOpen: false };
    }

    constructor(props: IDropdownProps) {
        super(props);
        this.state = this.getInitialState();
        this.toggle = this.toggle.bind(this);
        this.select = this.select.bind(this);
    }

    private getFirstError(): string | null {
        if (this.props.errorMessage && this.props.errorMessage.length > 0) {
            return this.props.errorMessage[0];
        }
        return null;
    }

    private getTextForItemKey(key: string): string | undefined {
        if (this.props.items) {
            const filtered = this.props.items.filter((item) => item.key === key);
            if (filtered.length > 0) {
                return filtered[0].text;
            }
        }
        return undefined;
    }

    public toggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    public select(event: any) {
        const newValue = event.target.attributes['data-key'].value;
        if (this.props.onChange) {
            this.props.onChange(this.props.name as string, newValue);
        }
    }

    private hasValue(): boolean {
        return !!this.props.value;
    }

    public render() {
        const renderDropdownItem = (listitem: IItem) => {
            return (
                <DropdownItem
                    key={listitem.key}
                    data-key={listitem.key}
                    onClick={this.select}
                >
                    {listitem.text}
                </DropdownItem>
            );
        };
        const listitems = this.props.items || [];
        return (
            <FormGroup className={this.props.className || ''}>
                <RSDropdown
                    id={this.props.id}
                    className={'abus-dropdown'}
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                >
                    <DropdownToggle
                        className={`w-100 text-left abus-toggle ${this.hasValue() ? 'used' : 'empty'} ${this.getFirstError() ? 'custom-invalid' : ''}`}
                        color="link"
                        caret={false}
                    >
                        <span>{this.getTextForItemKey(this.props.value as string) || '-'}</span>
                        <span className="float-right position-absolute r-0"><i className="icon-icon_control_arrow_down" /></span>
                    </DropdownToggle>
                    <span className="highlight" />
                    <span className="bar" />
                    <label>{this.props.placeholder}</label>
                    <DropdownMenu className="w-100">
                        {listitems.map(renderDropdownItem, this)}
                    </DropdownMenu>
                </RSDropdown>
                <FormFeedback className={this.getFirstError() ? 'custom-invalid' : ''}>
                    {this.getFirstError()}
                </FormFeedback>
            </FormGroup>
        );
    }
}
