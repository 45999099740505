import { pathToRegexp } from 'path-to-regexp';
import { KnownRoutes } from './knownRoutes';
import { Location } from 'history';
import { IRootStore } from '../../../rootStore';
import {
    IConsentManagementParams,
    IErrorRouteParams,
    IPasswordResetCallbackRouteParams,
    IPasswordResetRouteParams,
    IUnverifiedParams, IVerifyMailCallbackParams
} from './contracts';
import { APP_HOST_TYPE } from '../../../../getEnvConfig';
import { RegistrationTypes } from '../../services/auth0Service/contracts';
import { TokenScopes } from './viewStore';
import { defaultLogger } from '../../../../server/common/logging/defaultLogger';

export enum ViewNames {
  Login = 'login',
  Register = 'register',
  RegisterCustomer = 'registerCustomer',
  CompanyProfileEditor = 'companyProfileEditor',
  VerifymailCallback = 'verifymailcallback',
  PasswordResetCallback = 'passwordresetcallback',
  PasswordReset = 'passwordReset',
  VerifymailResendCompleted = 'verifymailresent',
  Imprint = 'imprint',
  Policy = 'policy',
  NotFound = 'notFound',
  ConsentManagement = 'ConsentManagement',
  Unverified = 'Unverified',
  Information = 'Information',
  ExtensionsError = 'ExtensionsError'
}

export interface IView {
  name: string;
  route: KnownRoutes;
  navigatableFromHostLimited?: APP_HOST_TYPE;
}

export interface IViewWInit<TRouteParams> extends IView {
  onEnterFn: (rootStore: IRootStore, routeParams: TRouteParams) => Promise<void>;
  runEnterFnOnServer: boolean;
}

export class ViewService {
  private static errorPageConfig: IViewWInit<IErrorRouteParams> = {
      name: ViewNames.ExtensionsError,
      route: KnownRoutes.ExtensionsError,
      onEnterFn: (rs, p) => {
          defaultLogger.error('[ErrorPage] Received error:', p);
          return Promise.resolve();
      },
      runEnterFnOnServer: true
  };

  private static loginViewConfig(): IView {
      return {
          name: ViewNames.Login,
          route: KnownRoutes.Default,
          navigatableFromHostLimited: 'staticpage'
      };
  }

  private static verifymailCallbackViewConfig(): IViewWInit<IVerifyMailCallbackParams> {
      return {
          name: ViewNames.VerifymailCallback,
          route: KnownRoutes.VerifymailCallback,
          onEnterFn: async (rs, p) => rs.verifymailStore.initializeForVerifymailCompletedRoute(p),
          runEnterFnOnServer: false,
          navigatableFromHostLimited: 'nodejs'
      };
  }

  private static verifymailResendCompletedViewConfig(): IView {
      return {
          name: ViewNames.VerifymailResendCompleted,
          route: KnownRoutes.VerifymailResendCompleted,
          navigatableFromHostLimited: 'nodejs'
      };
  }

  private static passwordResetViewConfig(): IViewWInit<IPasswordResetRouteParams> {
      return {
          name: ViewNames.PasswordReset,
          route: KnownRoutes.PasswordReset,
          onEnterFn: async (rs, params) => rs.passwordResetStore.initForPasswordResetRoute(params),
          runEnterFnOnServer: false,
          navigatableFromHostLimited: 'staticpage'
      };
  }

  private static passwordResetCallbackViewConfig(): IViewWInit<IPasswordResetCallbackRouteParams> {
      return {
          name: ViewNames.PasswordResetCallback,
          route: KnownRoutes.PasswordResetCallback,
          onEnterFn: async (rs, params) => rs.passwordResetStore.initForPasswordCallbackRoute(params),
          runEnterFnOnServer: false,
          navigatableFromHostLimited: 'nodejs'
      };
  }

  private static imprintViewConfig(): IView {
      return {
          name: ViewNames.Imprint,
          route: KnownRoutes.Imprint
      };
  }

  private static policyViewConfig(): IView {
      return {
          name: ViewNames.Policy,
          route: KnownRoutes.Policy
      };
  }

  private static registerConsumerViewConfig(): IViewWInit<undefined> {
      return {
          name: ViewNames.Register,
          route: KnownRoutes.RegisterConsumer,
          navigatableFromHostLimited: 'staticpage',
          runEnterFnOnServer: false,
          onEnterFn: async (rs) => {
              rs.registerStore.changeRegistrationType(RegistrationTypes.consumer);
          }
      };
  }

  private static registerCustomerViewConfig(): IViewWInit<undefined> {
      return {
          name: ViewNames.RegisterCustomer,
          route: KnownRoutes.RegisterCustomer,
          navigatableFromHostLimited: 'staticpage',
          runEnterFnOnServer: false,
          onEnterFn: async (rs) => {
              rs.registerStore.changeRegistrationType(RegistrationTypes.customer);
          }
      };
  }

  private static companyProfileEditorViewConfig(): IView {
      return {
          name: ViewNames.CompanyProfileEditor,
          route: KnownRoutes.CompanyProfileEditor
      };
  }

  private static consentViewConfig(): IViewWInit<IConsentManagementParams> {
      return {
          name: ViewNames.ConsentManagement,
          route: KnownRoutes.ConsentManagement,
          runEnterFnOnServer: false,
          onEnterFn: async (rs, p) => {
              if (p.token) {
                  rs.viewStore.user.setToken(p.token, TokenScopes.Consent);
              }
              rs.consentManagementStore.initializeForConsentManagementRoute(p);
          },
          navigatableFromHostLimited: 'nodejs'
      };
  }

  private static unverifiedViewConfig(): IViewWInit<IUnverifiedParams> {
      return {
          name: ViewNames.Unverified,
          route: KnownRoutes.Unverified,
          runEnterFnOnServer: false,
          onEnterFn: async (rs, p) => rs.unverifiedStore.initializeForUnverifiedRoute(p),
          navigatableFromHostLimited: 'nodejs'
      };
  }

  private static informationViewConfig(): IView {
      return {
          name: ViewNames.Information,
          route: KnownRoutes.Information
      };
  }

  private static allViews(): IView[] {
      return [
          ViewService.companyProfileEditorViewConfig(),
          ViewService.registerCustomerViewConfig(),
          ViewService.registerConsumerViewConfig(),
          ViewService.loginViewConfig(),
          ViewService.imprintViewConfig(),
          ViewService.policyViewConfig(),
          ViewService.verifymailCallbackViewConfig(),
          ViewService.verifymailResendCompletedViewConfig(),
          ViewService.passwordResetCallbackViewConfig(),
          ViewService.passwordResetViewConfig(),
          ViewService.consentViewConfig(),
          ViewService.unverifiedViewConfig(),
          ViewService.informationViewConfig(),
          ViewService.errorPageConfig
      ];
  }

  public static findViewByKnownRoute(path: KnownRoutes): IView | undefined {
      const view = ViewService.allViews().find((x) => x.route === path);
      return view;
  }

  public static findViewByName(viewname: ViewNames): IView | undefined {
      const view = ViewService.allViews().find((x) => x.name === viewname);
      return view;
  }

  public static findViewByLocation(location: Location): IView | undefined {
      const view = ViewService.allViews().find((x) => {
          const regexp = pathToRegexp(x.route);
          const doesmatch = regexp.test(location.pathname + location.search);
          return doesmatch;
      });
      return view;
  }

  public static getDefaultViewConfiguration(viewName: ViewNames): IView {
      const view = ViewService.allViews().find((x) => x.name === viewName);
      return view || ViewService.loginViewConfig();
  }
}
