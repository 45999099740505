import { IConsentModel } from './consentModel';

export const ConsentModelActions = (self: IConsentModel) => {
    return {
        validate() {
            self.isDirty = true;
            self.isValid = self.isOptional || self.consentGiven;
        },
        setConsent(consentGiven: boolean) {
            self.isDirty = true;
            self.consentGiven = consentGiven;
            self.validate();
        }
    };
};