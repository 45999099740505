import { Instance, types } from 'mobx-state-tree';
import { ConsentModelActions } from './consentModelActions';
import { IConsents } from './consents.interface';

export const ConsentModel = types.model({
    consentGiven: types.optional(types.boolean, false),
    isOptional: types.optional(types.boolean, false),
    consentType: types.enumeration<IConsents>(Object.values(IConsents)),
    isDirty: types.optional(types.boolean, false),
    isValid: types.maybeNull(types.boolean)
})
    .actions((self: any) => ConsentModelActions(self));

export interface IConsentModel extends Instance<typeof ConsentModel> { }