import { IAppConfiguration } from '../frontend/common/appconfiguration.interface';
import { getEnvConfig } from '../getEnvConfig';

export const getServerAppConfiguration = (): IAppConfiguration => {
    const config = getEnvConfig();
    const portalConfig: IAppConfiguration = {
        disabledFeaturesFlag: config.DISABLED_FEATURES_FLAG,
        node_env: config.NODE_ENV,
        hostType: config.APP_HOST,
        port: config.PORT,
        bffEndpointBase: config.BFF_BASE_URL,
        auth0domain: config.AUTH0.APP.DOMAIN,
        envStamping: {
            color: config.ENV.UI.COLOR,
            logo: config.ENV.UI.LOGO,
            name: config.ENV.UI.NAME
        }
    };
    return portalConfig;
};
