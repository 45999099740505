import { UsersApi } from 'abus-cloud-access-identity-backend';
import { BaseRepository } from '../common/baseRepository';

export class UnverifiedRepository extends BaseRepository {
    constructor(private token: string) {
        super();
    }

    public async resendVerificationMail(): Promise<void> {
        try {
            const api = new UsersApi({ ...this.bffProxyConfiguration, accessToken: this.token });
            await api.usersControllerResendverificationemail();
        } catch (e) {
            throw new Error(e);
        }
    }
}