import React from 'react';

import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';

import { useStore } from '../useStore';
import { IErrorRouteParams } from '../common/stores/viewStore/contracts';

import './errorPage.scss';

export const ErrorPage = () => {
    const [t] = useTranslation('aiapp_errors');
    const { viewStore } = useStore();
    const routeParams = viewStore.currentView.params as IErrorRouteParams;
    const source = routeParams.source.toLowerCase();
    const errorDescription = routeParams.errorDescription.toLowerCase();
    const specificErrorKey = `ErrorPage.Error.Source=${source}.Desc=${errorDescription}`;
    const sourceOnlyErrorKey = `ErrorPage.Error.Source=${source}`;
    
    return useObserver(() => {
        return (<div className="errorPage responsive-page-grid">
            <section className="responsive-page-content">
                <header className="content-header row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <span className="vertical-heading-decorator">
                            <span className="typo-s2">{t('ErrorPage.Header.Sub')}</span>
                            <h2 className="typo-h2">{t('ErrorPage.Header.Main')}</h2>
                        </span>
                    </div>
                </header>
                <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <p>
                            {t([specificErrorKey, sourceOnlyErrorKey, 'ErrorPage.DefaultErrorMessage'].filter((x) => x != null))}
                        </p>
                    </div>
                </main>
                <footer className="content-footer transparent-lg-style row no-gutters justify-content-center pb-3" />
            </section>
            <aside className="secondary-area" />
        </div>);
    });
};
