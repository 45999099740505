/* Caution! Standalone page urls must be in sync with rules! */
export enum KnownRoutes {
  Default = '/',
  RegisterConsumer = '/register',
  RegisterCustomer = '/register/customer',
  CompanyProfileEditor = '/register/companyprofile',
  VerifymailCallback = '/login/verifymailcallback/:appId/:success/:message',
  PasswordResetCallback = '/login/passwordresetcallback/:appId/:success',
  PasswordReset = '/login/passwordrecovery/:appId/:email?',
  VerifymailResendCompleted = '/login/verifymailresent',
  ConsentManagement = '/consents\\?token=:token?&state=:state',
  Unverified = '/unverified\\?token=:token?&state=:state',
  ExtensionsError = '/exterror/:source/:errorDescription\\?errorid=:errorid?&state=:state?',
  Information = '/information/:appName/:displayTextKey',
  Imprint = '/imprint',
  Policy = '/policy'
}