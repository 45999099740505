import { IRegisterStore } from './registerStoreModel';
import { RegistrationTypes, IAuth0RegistrationMetadataDecoded } from '../../common';

function createUserProfileData(
    store: IRegisterStore,
    clientId: string): IAuth0RegistrationMetadataDecoded {
    const auth0param: IAuth0RegistrationMetadataDecoded = {
        firstName: store.commonRegistrationModel.firstName,
        lastName: store.commonRegistrationModel.lastName,
        registrationDate: (new Date()).toJSON(),
        registrationType: store.registrationType,
        newsletterConsent: store.commonRegistrationModel.isNewsletterApproved,
        clientId
    };

    if (store.registrationType === RegistrationTypes.customer) {
        auth0param.customerId = store.customerRegistrationModel.customerID;
        if (!store.customerRegistrationModel.customerWithCustomerID) {
            auth0param.cCity = store.companyProfileModel.city;
            auth0param.cName = store.companyProfileModel.companyName;
            auth0param.cCountry = store.companyProfileModel.country;
            auth0param.cHouseNumber = store.companyProfileModel.houseNumber;
            auth0param.cPhone = store.companyProfileModel.phone;
            auth0param.cPostalCode = store.companyProfileModel.postalCode;
            auth0param.cStreet = store.companyProfileModel.street;
            auth0param.cTaxId = store.companyProfileModel.taxId;
        }
    }

    return auth0param;
}

export default function createUserMetadataParam(
    store: IRegisterStore,
    clientId: string): IAuth0RegistrationMetadataDecoded {
    return createUserProfileData(
        store,
        clientId);
}
