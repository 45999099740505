import * as React from 'react';
import { useTranslation } from 'react-i18next';

import './footer.scss';
import { isValidEnvValue } from '../isValidEnvValue';
import { useStore } from '../../../useStore';
import { getAppConfiguration } from '../../../getAppConfiguration';

const Footer = () => {
    const { t } = useTranslation('aiapp_default');
    const { showImprint, showPolicy } = useStore().viewStore;
    const { envStamping } = getAppConfiguration();
    const color = envStamping.color;

    const footerStyle: React.CSSProperties = {};

    if (isValidEnvValue(color)) {
        footerStyle.backgroundColor = color;
    }

    return (
        <footer className="app-footer container-fluid justify-content-center" style={footerStyle}>
            <div className="d-flex flex-wrap">
                <div className="p-1 flex-grow-md-0 flex-grow-1"><span className="typo-t2 text-white">{t('CopyrightNotice')}</span></div>
                <div className="">
                    <button className="btn btn-link no-hover border-0 p-1 m-0" onClick={() => showImprint()}>
                        <span className="typo-t2 text-white">{t('Imprint')}</span></button>
                    <span className="p-1 typo-t2 text-white">|</span>
                    <button className="btn btn-link no-hover border-0 p-1 m-0" onClick={() => showPolicy()}>
                        <span className="typo-t2 text-white">{t('Policy')}</span></button>
                </div></div>
        </footer>
    );
};

export default Footer;