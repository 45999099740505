import { getServerAppConfiguration } from '../server/getServerAppConfiguration';
import { isServer } from '../isServer';
import { IAppConfiguration } from './common/appconfiguration.interface';

const getBrowserAppConfiguration = (): IAppConfiguration => {
    const appConfiguration: IAppConfiguration = window.initialAppConfig;
    const portalConfig: IAppConfiguration = {
        disabledFeaturesFlag: appConfiguration.disabledFeaturesFlag,
        node_env: appConfiguration.node_env,
        bffEndpointBase: appConfiguration.bffEndpointBase,
        port: appConfiguration.port,
        hostType: appConfiguration.hostType,
        auth0domain: appConfiguration.auth0domain,
        envStamping: {
            color: appConfiguration.envStamping?.color,
            logo: appConfiguration.envStamping?.logo,
            name: appConfiguration.envStamping?.name
        }
    };
    return portalConfig;
};

export const getAppConfiguration = (): IAppConfiguration => {
    return isServer() ? getServerAppConfiguration() : getBrowserAppConfiguration();
};
