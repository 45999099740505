import 'reflect-metadata';
import { maxLengthMetadataKey } from './constants';

export interface IMaxLengthMetadata {
    maxLength: number;
    errorMessageLabel: string;
}

export function getMaxLength<T, K extends keyof T>(target: T, propertyKey: K): IMaxLengthMetadata {
    return Reflect.getMetadata(maxLengthMetadataKey, target, propertyKey as string);
}

export function maxLength(args: IMaxLengthMetadata) {
    return Reflect.metadata(maxLengthMetadataKey, args);
}

export function hasMaxLength<T, K extends keyof T>(target: T, propertyKey: K): boolean {
    const meta = getMaxLength(target, propertyKey);
    return !!meta;
}

export function validateMaxLength<T, K extends keyof T>(target: T, propertyKey: K): string | null {
    const meta = getMaxLength(target, propertyKey);
    if (hasMaxLength(target, propertyKey)) {
        const value: string = target[propertyKey] as any;
        if (value && value.length > meta.maxLength) {
            return meta.errorMessageLabel;
        }
    }

    return null;
}
