import moment from 'moment';
import { IAuth0RegistrationMetadataDecoded } from './contracts';
import { IRegistrationUserMetadata } from '@abussc/accessidentity-contracts/auth0/userMetadata';

export class Auth0MetaDataSerializer {
    private readonly _AUTH0_MAX_CHARS_PER_PROPERTY = 230;
    private readonly _AUTH0_MAX_PROPERTIES = 9;
    private readonly _AUTH0_PROP_NAME = 'registrationData';

    private stringToChunks(str: string, size: number): string[] {
        const chunks = [];

        for (let i = 0, charsLength = str.length; i < charsLength; i += size) {
            chunks.push(str.substring(i, i + size));
        }

        return chunks;
    }

    public deserialize(serializedmetadata: IRegistrationUserMetadata): IAuth0RegistrationMetadataDecoded {
        let encodedString = '';

        for (let index = 0; index < this._AUTH0_MAX_PROPERTIES; index++) {
            const elementval = serializedmetadata[this._AUTH0_PROP_NAME + index];
            if (typeof elementval === 'string') {
                encodedString += elementval;
            }
        }

        const parsed = JSON.parse(decodeURIComponent(encodedString));
        for (const key in parsed) {
            if (Object.prototype.hasOwnProperty.call(parsed, key)) {
                const element = parsed[key];
                const mm = moment(new Date(element));
                if (typeof (element) === 'string' && isNaN(element as any) && mm.isValid()) {
                    parsed[key] = mm.toDate().toJSON();
                }
            }
        }
        return parsed;
    }

    public serialize(userMetadata: IAuth0RegistrationMetadataDecoded): IRegistrationUserMetadata {
        const encodedString = this.encodeString(userMetadata);

        if (encodedString.length > this._AUTH0_MAX_CHARS_PER_PROPERTY * this._AUTH0_MAX_PROPERTIES) {
            throw new Error('The provided user meta data exceeds the limit of ' + this._AUTH0_MAX_CHARS_PER_PROPERTY *
                this._AUTH0_MAX_PROPERTIES + ' chars');
        }

        const chunks = this.stringToChunks(encodedString, this._AUTH0_MAX_CHARS_PER_PROPERTY);
        const auth0UserMetadata: IRegistrationUserMetadata = {};

        // Cap max 10
        for (let index = 0; (index < chunks.length) && (index < 10); index++) {
            auth0UserMetadata[this._AUTH0_PROP_NAME + index] = chunks[index];
        }

        return auth0UserMetadata;
    }

    private encodeString(data: any): string {
        // First serialize to json then URI encode
        return encodeURIComponent(JSON.stringify(data));
    }
}
