import { isServer } from '../../isServer';
import { getAppConfiguration } from '../getAppConfiguration';

export const bffBaseUrl = (): string => {
    const appconfig = getAppConfiguration();
    
    // wenn static page (hosted by auth0), wird unser gehosterer endpoint aufgerufen
    if (appconfig.hostType === 'staticpage') {
        return appconfig.bffEndpointBase;
    }

    // wenn nodejs backend selbst, rufe sich selbst auf mit richtigem port
    if (isServer()) {
        return `http://localhost:${appconfig.port}`;
    }

    // wenn es sich um den browser handelt
    return appconfig.bffEndpointBase;
};
