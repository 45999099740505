import { EmailPattern } from '../../../common/constants';
import { isEqual, pattern, required } from '../../../common/validation';

export class CommonRegistrationValidationModel {
  @pattern({
      // tslint:disable-next-line
      pattern: EmailPattern,
      errorMessageLabel: 'email_format'
  })
  public email = '';

  public isConfirmed = false;

  @required({ errorMessageLabel: 'password_required' })
  public password = '';

  @required({ errorMessageLabel: 'firstname_required' })
  public firstName = '';

  @required({ errorMessageLabel: 'lastname_required' })
  public lastName = '';

  @isEqual(
      {
          match: true,
          errorMessageLabel: 'approve_gdpr'
      })
  public isGDPRApproved = false;
}
