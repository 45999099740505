import { useObserver } from 'mobx-react';
import React from 'react';
import { LabeledCheckbox } from '../../common/components/formControlls/labeledCheckbox';
import { IExtendedRegistrationModel } from '../store/extendedRegistrationModel';
import { Trans, useTranslation } from 'react-i18next';
import { useStore } from '../../useStore';
import { nameof } from '../../common';
import Input from '../../common/components/input';

const I18NEXT_NAMESPACE = ['aiapp_register', 'aiapp_errors'];

export const CompanyIdSection = () => {
    const [t] = useTranslation(I18NEXT_NAMESPACE);
    const { registerStore } = useStore();

    function localizeErrors(errors: string[] | undefined) {
        if (errors) {
            return errors.map((error: string) => t(`aiapp_errors:${error}`));
        }
        return [];
    }

    function updateRegistrationModelValue<T extends keyof IExtendedRegistrationModel>(elementName: T, newValue: IExtendedRegistrationModel[T]) {
        registerStore.customerRegistrationModel.updateValue(elementName, newValue);
    }
    
    return useObserver(() => {
        return (<div className="mt-5 mb-5">
            <LabeledCheckbox
                name={nameof<IExtendedRegistrationModel>('customerWithCustomerID')}
                className="mb-2"
                checked={registerStore.customerRegistrationModel.customerWithCustomerID}
                onChange={(e) => updateRegistrationModelValue('customerWithCustomerID', e.value)}
            >
                <label className="d-inline typo-c1">
                    <Trans
                        ns={I18NEXT_NAMESPACE}
                        i18nKey="RegisterWithABUSCustomerId"
                        components={[<span key="0" className="typo-highlight">__</span>]}
                    />
                </label>
            </LabeledCheckbox>

            <span className="typo-t6"><Trans ns={I18NEXT_NAMESPACE}>CustomerIdExplanation</Trans></span>
            <Input
                name={nameof<IExtendedRegistrationModel>('customerID')}
                className={'mt-4'}
                type="text"
                onChange={(name, value) => { updateRegistrationModelValue(name as any, value) }}
                placeholder={t('CustomerID')}
                readOnly={!registerStore.customerRegistrationModel.customerWithCustomerID}
                value={registerStore.customerRegistrationModel.customerID}
                errorMessage={localizeErrors((registerStore.customerRegistrationModel.errors as any).customerID)}
            />
        </div>);
    });
};