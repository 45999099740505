import { LoginStore } from './login/loginStoreModel';
import { ViewsService, ViewNames } from './common/index';
import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { TokenScopes, ViewStore } from './common/stores/viewStore/viewStore';
import { RegisterStore } from './register';
import { VerifymailStore } from './verifymail/verifymailStoreModel';
import { IAppConfiguration } from './common/appconfiguration.interface';
import { PasswordResetStore } from './passwordReset/store/passwordResetStore.model';
import { History } from 'history';
import { ConsentManagementStore } from './consentManagement/store/consentManagementStore';
import { UnverifiedStore } from './unverified/unverifiedStore';
import { i18n } from 'i18next';

export interface IStoreInject {
  history: History;
  authZeroConfig: any;
  appConfig: IAppConfiguration;
  i18n: i18n;
  isServer: boolean;
}

export const RootStore = types.model({
    loginStore: types.optional(LoginStore, {}),
    registerStore: types.optional(RegisterStore, {}),
    verifymailStore: types.optional(VerifymailStore, {}),
    passwordResetStore: types.optional(PasswordResetStore, {}),
    consentManagementStore: types.optional(ConsentManagementStore, {}),
    unverifiedStore: types.optional(UnverifiedStore, {}),
    viewStore: ViewStore
});

export interface IRootStore extends Instance<typeof RootStore> { }

export const createRootStore = (initialstate: SnapshotIn<typeof RootStore>, inject: IStoreInject) => {
    return RootStore.create(initialstate, inject);
};

export const createDefaultState = (accesTokken: string | null): SnapshotIn<typeof RootStore> => {
    const devaultView = ViewsService.getDefaultViewConfiguration(ViewNames.Login);
    return { viewStore: { currentView: { name: devaultView.name, route: devaultView.route }, user: { accessToken: accesTokken, tokenScope: TokenScopes.None }, features: {} } };
};