import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { IRegistrationModel } from '../store/commonRegistrationModel';
import { nameof } from '../../common';
import Input from '../../common/components/input';
import { useStore } from '../../useStore';

const I18NEXT_NAMESPACE = ['aiapp_register', 'aiapp_errors'];

export const NamesSection = () => {
    const [t] = useTranslation(I18NEXT_NAMESPACE);
    const { registerStore } = useStore();

    function localizeErrors(errors: string[]) {
        if (errors) {
            return errors.map((error: string) => t(`aiapp_errors:${error}`));
        }
        return [];
    }

    function updateRegistrationModelValue<T extends keyof IRegistrationModel>(elementName: T, newValue: IRegistrationModel[T]) {
        registerStore.commonRegistrationModel.updateValue(elementName, newValue);
    }

    return useObserver(() => {
        return (
            <section className="consentAreaComponent">
                <Input
                    name={nameof<IRegistrationModel>('firstName')}
                    type="text"
                    onChange={(name, value) => { updateRegistrationModelValue(name as any, value) }}
                    placeholder={t('aiapp_register:Firstname')}
                    value={registerStore.commonRegistrationModel.firstName}
                    errorMessage={localizeErrors(registerStore.commonRegistrationModel.firstNameErrors)}
                />

                <Input
                    name={nameof<IRegistrationModel>('lastName')}
                    type="text"
                    onChange={(name, value) => { updateRegistrationModelValue(name as any, value) }}
                    placeholder={t('aiapp_register:Lastname')}
                    value={registerStore.commonRegistrationModel.lastName}
                    errorMessage={localizeErrors((registerStore.commonRegistrationModel.lastNameErrors))}
                />
            </section>
        );
    });
};
export default NamesSection;