export default class Helper {
    public static isEmpty(obj: Record<string, any>) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                return false;
            }
        }
        return true;
    }

    public static jsonToModel<I, T extends I>(object: I, Type: (new () => T)): T {
        let instance: any = new Type();
        instance = Object.assign(instance, object);
        return instance;
    }
}