import { ServerRoutes } from '../../server/serverRoutes';
import { IVerificationMailResendCompletedParams, IVerifyMailCallbackParams } from '../common/stores/viewStore/contracts';
import { IVerifymailStore, IVerifymailStoreActions } from './verifymailStoreModel';

function isSuccesMessage(message: string | undefined): boolean {
    const handleAsSuccessMessages: Map<string, boolean> = new Map<string, boolean>([['ThisURLcanbeusedonlyonce', true]]);

    if (message === undefined) {
        return false;
    }
    
    const isSuccessMessage = handleAsSuccessMessages.get(message);

    return isSuccessMessage !== undefined && isSuccessMessage;
}

function isSuccess(params: Partial<IVerifyMailCallbackParams>): boolean {
    return (params.success === 'true') || ((params.success === 'false') && isSuccesMessage(params.message));
}
function shouldShowSuccessConfirmation(params: Partial<IVerifyMailCallbackParams>): boolean {
    return !((params.success === 'false') && isSuccesMessage(params.message));
}

export const verifymailStoreActions = (self: IVerifymailStore): IVerifymailStoreActions => {
    return ({
        initializeForVerifymailCompletedRoute: (params: IVerifyMailCallbackParams) => {
            self.changeReceived = params.success !== undefined;
            self.changeSuccessful = isSuccess(params);
            self.showSuccessConfirmation = shouldShowSuccessConfirmation(params);
            self.message = params.message;
        },
        initializeForVerifymailResendCompletedRoute: (params: IVerificationMailResendCompletedParams) => {
            // check if still needed
        },
        checkShouldUnlock: () => {
            self.secondsToUnlock--;
            if (self.secondsToUnlock === 0) {
                self.sendEnabled = true;
            } else {
                setTimeout(() => self.checkShouldUnlock(), 1000);
            }
        },
        startReenableTimer: () => {
            self.secondsToUnlock = 30;
            setTimeout(() => self.checkShouldUnlock(), 1000);
        },
        startVerificationResendFlow: () => {
            window.location.assign(ServerRoutes.ResendVerification);
        }
    });
};
