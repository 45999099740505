import React, { CSSProperties } from 'react';
import defaultLogo from './ABUS_logo_web_reduziert.svg';
import { isValidEnvValue } from '../isValidEnvValue';
import { getAppConfiguration } from '../../../getAppConfiguration';

import './header.scss';

const LogoStamp = ({ name }: { name: string | undefined }) => {
    return (<span className="logo-env-name"><span className="inner">{name}</span></span>);
};

const Header = () => {
    const { logo, name, color } = getAppConfiguration().envStamping;
    const headerStyle: CSSProperties = {};

    if (isValidEnvValue(color)) {
        headerStyle.backgroundColor = color;
    }

    return (
        <header className="app-header" style={headerStyle}>
            <nav className="navbar headerbar row no-gutters">
                <div className="col-1" />
                <div className="col-10">
                    <span className="navbar-brand w-100 d-flex justify-content-center">
                        <div className="position-relative">
                            <img className="logo" height="40" src={isValidEnvValue(logo) ? logo : defaultLogo} />
                            {isValidEnvValue(name) && <LogoStamp name={name} />}
                        </div>
                    </span>
                </div>
                <div className="col-1" />
            </nav>
        </header>
    );
};

export default Header;
