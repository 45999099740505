import { EmailPattern } from '../common/constants';
import { pattern } from '../common/validation';

export class PasswordChangeRedirectValidationModel {
    @pattern({
        pattern: EmailPattern,
        errorMessageLabel: 'email_format'
    })
    public email = '';

    constructor(email: string) {
        this.email = email;
    }
}
