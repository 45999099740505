import { useObserver } from 'mobx-react';
import React from 'react';
import { Skeleton } from '../../common/components/skeleton';
import { IRemoteDataSyncStates } from '../../common/stores/remoteDataSyncStateModel';
import { useStore } from '../../useStore';
import { Consent } from './consent';

export const MainContent = () => {
    const { consentManagementStore } = useStore();
    const userConsents = consentManagementStore.userConsents;
    return useObserver(() => {
        if (consentManagementStore.userConsents.isDataLoadingState) {
            return <>
                <Skeleton className="d-block mb-2" style={{ height: '56px' }} />
                <Skeleton className="d-block mb-1" style={{ height: '18px' }} />
                <Skeleton className="d-block mb-1" style={{ height: '18px' }} />
                <Skeleton className="d-block mb-3" style={{ height: '18px' }} />
            </>;
        } else if (consentManagementStore.userConsents.state === IRemoteDataSyncStates.LoadError) {
            return null;
        }
        const appNameKey = userConsents.applicationName;
        return (<>
            <div className="col-lg-10 col-xl-8">
                {userConsents.consents.map((x, i) => <Consent key={i} applicationName={appNameKey} consent={x} />)}
            </div>
        </>);
    });
};