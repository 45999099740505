import { IExtendedRegistrationModelActions } from './extendedRegistrationModel';
import { ExtendedRegistrationVerificationModel } from './verification/extendedRegistrationVerificationModel';

export const extendedRegistrationModelActions = (self: any): IExtendedRegistrationModelActions => {
    return ({
        updateValue: (elementName, newValue) => {
            if (elementName === 'customerWithCustomerID' && newValue === false) {
                self.customerID = '';
            }
            self[elementName] = newValue;
        },
        clearErrors: () => {
            self.errors = {};
        },
        validate: () => {
            const v = new ExtendedRegistrationVerificationModel();
            v.customerID = self.customerID;
            v.customerWithCustomerID = self.customerWithCustomerID;
            const result = v.validate();
            self.errors = v.errors;
            return result;
        }
    });
};