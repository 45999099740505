import { ValidatonService, maxLength, required, pattern } from '../../../common/validation';
import Helper from '../../../common/helper';
import { ErrorType } from '../ErrorType';

export type CompanyProfileErrors = ErrorType<CompanyProfileVerificationModel>;

export class CompanyProfileVerificationModel {
    private _validationService: ValidatonService = new ValidatonService();

    @required({ errorMessageLabel: 'errors:required' })
    @maxLength({ maxLength: 50, errorMessageLabel: 'errors:companyName-max-length' })
    public companyName?: string;

    @required({ errorMessageLabel: 'errors:required' })
    @maxLength({ maxLength: 50, errorMessageLabel: 'errors:street-max-length' })
    public street?: string;

    @required({ errorMessageLabel: 'errors:required' })
    @maxLength({ maxLength: 10, errorMessageLabel: 'errors:houseNumber-max-length' })
    public houseNumber?: string;

    @required({ errorMessageLabel: 'errors:required' })
    @maxLength({ maxLength: 5, errorMessageLabel: 'errors:invalid_country' })
    public country?: string;

    @required({ errorMessageLabel: 'errors:required' })
    @maxLength({ maxLength: 50, errorMessageLabel: 'errors:city-max-length' })
    public city?: string;

    @required({ errorMessageLabel: 'errors:required' })
    @maxLength({ maxLength: 10, errorMessageLabel: 'errors:postalCode-max-length' })
    public postalCode?: string;

    @required({ errorMessageLabel: 'errors:required' })
    @maxLength({ maxLength: 50, errorMessageLabel: 'errors:taxId-max-length' })
    public taxId?: string;

    @required({ errorMessageLabel: 'errors:required' })
    @maxLength({ maxLength: 50, errorMessageLabel: 'errors:phone-max-length' })
    @pattern({ pattern: '^(?:00|\\+)[0-9\\s\\/-]{6,255}$', errorMessageLabel: 'errors:invalid_phone' })
    public phone?: string;

    public errors: CompanyProfileErrors = {};

    public validate(): boolean {
        const errors = this._validationService.validate<CompanyProfileVerificationModel>(this) as (CompanyProfileErrors | null);
        this.errors = errors || {};
        return Helper.isEmpty(this.errors);
    }

    public clearErrors() {
        this.errors = {};
    }
}