import { IDISABLED_FEATURES_FLAGS } from '../../../getEnvConfig';
import { getAppConfiguration } from '../../getAppConfiguration';

// ****** HOW FLAGS ARE USED IN TS/JS ******
// https://basarat.gitbook.io/typescript/type-system/enums#number-enums-as-flags
export class FeaturesService {
    public static get registrationEnabled(): boolean {
        const { disabledFeaturesFlag } = getAppConfiguration();
        const hasRegistrationFlag = disabledFeaturesFlag & IDISABLED_FEATURES_FLAGS.Registration;
        return !hasRegistrationFlag;
    }
}