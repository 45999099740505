import { i18n } from 'i18next';
import { IConsents } from './store/consents.interface';

export class GDPRTranslationSelector {
    constructor(private options: { i18next: i18n, appName: string, useFallback: boolean }) { }

    public get dataStorageTermsLink() {
        return this.getLabelForType(IConsents.DataStorageTerms);
    }

    public get termsAndConditionsLink() {
        return this.getLabelForType(IConsents.TermsAndConditions);
    }

    public getLabelForType(consenttype: IConsents) {
        const fallbackGeneralLabel = this.options.i18next.t(`ConsentManagement.Consents.Link.Type=${consenttype.toLowerCase()}`);
        const keyAppSepcific = `ConsentManagement.Consents.Link.AppName=${this.options.appName.toLowerCase()}.Type=${consenttype.toLowerCase()}`;
        const appSpecificLabel = this.options.i18next.t(keyAppSepcific, this.options.useFallback ? { defaultValue: fallbackGeneralLabel } : undefined);
        return appSpecificLabel;
    }
}
