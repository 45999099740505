import * as React from 'react';
import { useObserver } from 'mobx-react';
import { Trans } from 'react-i18next';
import { ViewNames } from '../common/index';
import RegisterCustomer from './registerForm/registerForm';
import { useStore } from '../useStore';

import './registerNavigation.scss';

interface IRegisterNavigationProps {
  className?: string;
}

const I18NEXT_NAMESPACE = ['aiapp_register', 'aiapp_errors'];

const RegisterNavigation = (props: IRegisterNavigationProps) => {
    const { viewStore } = useStore();

    return useObserver(() => {
        function customerViewIsActive() {
            return viewStore.currentView.name === ViewNames.Register;
        }

        function renderCurrentRegisterView() {
            const store = viewStore;
            const view = store.currentView;

            switch (view.name) {
            case ViewNames.RegisterCustomer:
            case ViewNames.Register:
                return <RegisterCustomer />;
            default:
                return;
            }
        }

        function renderTabNavigation() {
            return (<nav className="content-nav row no-gutters justify-content-center tab-group">

                <button
                    className="col-6 col-lg-5 col-xl-4 btn btn-tab-switch"
                    disabled={customerViewIsActive()}
                    onClick={() => viewStore.showRegister()}
                ><label><Trans ns={I18NEXT_NAMESPACE}>AsConsumer</Trans></label>
                </button>

                <button
                    className="col-6 col-lg-5 col-xl-4 btn btn-tab-switch"
                    disabled={!customerViewIsActive()}
                    onClick={() => viewStore.showRegisterCustomer()}
                >
                    <label><Trans ns={I18NEXT_NAMESPACE}>AsCustomer</Trans></label>
                </button>
            </nav>);
        }

        return (
            <div className={`${props.className || ''}`}>
                <header className="content-header row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <span className="vertical-heading-decorator">
                            <h2 className="typo-h2" ><Trans ns={I18NEXT_NAMESPACE}>Title</Trans></h2>
                        </span>
                    </div>
                    <div className="col-lg-10 col-xl-8"><p className="typo-c1">
                        <Trans ns={I18NEXT_NAMESPACE}>Motivationtext</Trans>
                    </p></div>
                </header>
                {renderTabNavigation()}
                <main className="content-main pt-4 secondary-style">{renderCurrentRegisterView()}</main>
                <footer className="content-footer secondary-style" />
            </div>
        );
    });
};

export default RegisterNavigation;