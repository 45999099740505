"use strict";
// tslint:disable
/**
 * AccessAndIdentity Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersApi = exports.UsersApiFactory = exports.UsersApiFp = exports.UsersApiAxiosParamCreator = exports.RegistrationApi = exports.RegistrationApiFactory = exports.RegistrationApiFp = exports.RegistrationApiAxiosParamCreator = exports.PasswordApi = exports.PasswordApiFactory = exports.PasswordApiFp = exports.PasswordApiAxiosParamCreator = exports.ConsentsApi = exports.ConsentsApiFactory = exports.ConsentsApiFp = exports.ConsentsApiAxiosParamCreator = exports.Consents = void 0;
const globalImportUrl = require("url");
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
var Consents;
(function (Consents) {
    Consents["TermsAndConditions"] = "TermsAndConditions";
    Consents["DataStorageTerms"] = "DataStorageTerms";
    Consents["Communication"] = "Communication";
})(Consents = exports.Consents || (exports.Consents = {}));
/**
 * ConsentsApi - axios parameter creator
 * @export
 */
exports.ConsentsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentsControllerGetCurrent: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/consents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {UpdateUserConsentsDto} updateUserConsentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentsControllerSave: (updateUserConsentsDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'updateUserConsentsDto' is not null or undefined
            if (updateUserConsentsDto === null || updateUserConsentsDto === undefined) {
                throw new base_1.RequiredError('updateUserConsentsDto', 'Required parameter updateUserConsentsDto was null or undefined when calling consentsControllerSave.');
            }
            const localVarPath = `/api/consents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof updateUserConsentsDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(updateUserConsentsDto !== undefined ? updateUserConsentsDto : {}) : (updateUserConsentsDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ConsentsApi - functional programming interface
 * @export
 */
exports.ConsentsApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentsControllerGetCurrent(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ConsentsApiAxiosParamCreator(configuration).consentsControllerGetCurrent(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {UpdateUserConsentsDto} updateUserConsentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentsControllerSave(updateUserConsentsDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ConsentsApiAxiosParamCreator(configuration).consentsControllerSave(updateUserConsentsDto, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * ConsentsApi - factory interface
 * @export
 */
exports.ConsentsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentsControllerGetCurrent(options) {
            return exports.ConsentsApiFp(configuration).consentsControllerGetCurrent(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UpdateUserConsentsDto} updateUserConsentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consentsControllerSave(updateUserConsentsDto, options) {
            return exports.ConsentsApiFp(configuration).consentsControllerSave(updateUserConsentsDto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ConsentsApi - object-oriented interface
 * @export
 * @class ConsentsApi
 * @extends {BaseAPI}
 */
class ConsentsApi extends base_1.BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentsApi
     */
    consentsControllerGetCurrent(options) {
        return exports.ConsentsApiFp(this.configuration).consentsControllerGetCurrent(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {UpdateUserConsentsDto} updateUserConsentsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentsApi
     */
    consentsControllerSave(updateUserConsentsDto, options) {
        return exports.ConsentsApiFp(this.configuration).consentsControllerSave(updateUserConsentsDto, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ConsentsApi = ConsentsApi;
/**
 * PasswordApi - axios parameter creator
 * @export
 */
exports.PasswordApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {PasswordResetDto} passwordResetDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordControllerReset: (passwordResetDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'passwordResetDto' is not null or undefined
            if (passwordResetDto === null || passwordResetDto === undefined) {
                throw new base_1.RequiredError('passwordResetDto', 'Required parameter passwordResetDto was null or undefined when calling passwordControllerReset.');
            }
            const localVarPath = `/api/password/reset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof passwordResetDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(passwordResetDto !== undefined ? passwordResetDto : {}) : (passwordResetDto || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PasswordApi - functional programming interface
 * @export
 */
exports.PasswordApiFp = function (configuration) {
    return {
        /**
         *
         * @param {PasswordResetDto} passwordResetDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordControllerReset(passwordResetDto, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PasswordApiAxiosParamCreator(configuration).passwordControllerReset(passwordResetDto, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * PasswordApi - factory interface
 * @export
 */
exports.PasswordApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {PasswordResetDto} passwordResetDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordControllerReset(passwordResetDto, options) {
            return exports.PasswordApiFp(configuration).passwordControllerReset(passwordResetDto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
class PasswordApi extends base_1.BaseAPI {
    /**
     *
     * @param {PasswordResetDto} passwordResetDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    passwordControllerReset(passwordResetDto, options) {
        return exports.PasswordApiFp(this.configuration).passwordControllerReset(passwordResetDto, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.PasswordApi = PasswordApi;
/**
 * RegistrationApi - axios parameter creator
 * @export
 */
exports.RegistrationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationControllerContinue: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/registration/continue`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * RegistrationApi - functional programming interface
 * @export
 */
exports.RegistrationApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationControllerContinue(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.RegistrationApiAxiosParamCreator(configuration).registrationControllerContinue(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * RegistrationApi - factory interface
 * @export
 */
exports.RegistrationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationControllerContinue(options) {
            return exports.RegistrationApiFp(configuration).registrationControllerContinue(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * RegistrationApi - object-oriented interface
 * @export
 * @class RegistrationApi
 * @extends {BaseAPI}
 */
class RegistrationApi extends base_1.BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    registrationControllerContinue(options) {
        return exports.RegistrationApiFp(this.configuration).registrationControllerContinue(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.RegistrationApi = RegistrationApi;
/**
 * UsersApi - axios parameter creator
 * @export
 */
exports.UsersApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendverificationemail: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/users/resendverificationemail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication jwt required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * UsersApi - functional programming interface
 * @export
 */
exports.UsersApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendverificationemail(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UsersApiAxiosParamCreator(configuration).usersControllerResendverificationemail(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * UsersApi - factory interface
 * @export
 */
exports.UsersApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendverificationemail(options) {
            return exports.UsersApiFp(configuration).usersControllerResendverificationemail(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
class UsersApi extends base_1.BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    usersControllerResendverificationemail(options) {
        return exports.UsersApiFp(this.configuration).usersControllerResendverificationemail(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.UsersApi = UsersApi;
