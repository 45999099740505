import React, { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { createBrowserHistory, createHashHistory } from 'history';

import App from './frontend/app';
import i18n from './i18n';
import { createDefaultState, createRootStore } from './frontend/rootStore';
import { StoreProvider } from './frontend/storeProvider';
import { authzeroconfigDecoder } from './frontend/common/authzeroconfigdecoder';
import { connectReduxDevtools } from 'mst-middlewares';
import { getAppConfiguration } from './frontend/getAppConfiguration';
import { isServer } from './isServer';

const boostrapApp = async () => {
    if (!i18n.isInitialized) {
        await i18n.init();
    }

    const appConfig = getAppConfiguration();
    const history = appConfig.hostType === 'nodejs' ? createBrowserHistory() : createHashHistory();
    const zeroConfig = authzeroconfigDecoder(window.initialZeroConfig);
    const rootStoreSnapshot = appConfig.hostType === 'nodejs' ? window.initialStore : createDefaultState(null);
    const rootStore = createRootStore(rootStoreSnapshot, { history, authZeroConfig: zeroConfig, appConfig, i18n, isServer: false });
    if (appConfig.node_env === 'development' && !isServer()) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        connectReduxDevtools(require('remotedev'), rootStore);
    }

    await rootStore.viewStore.initViewstore();

    hydrate(
        <Suspense fallback={() => '🛠️ wiring things up...'}>
            <I18nextProvider i18n={i18n}>
                <StoreProvider store={rootStore} >
                    <App />
                </StoreProvider>
            </I18nextProvider>
        </Suspense>,
        document.getElementById('root')
    );
};
boostrapApp();

if (module.hot) {
    module.hot.accept();
}
