import React from 'react';
import { useObserver } from 'mobx-react';
import { useStore } from '../useStore';
import { LocalizedErrorFeedback } from '../common/components/localizedErrorFeedback';

import { HeaderContent } from './components/headerConent';
import { MainContent } from './components/mainContent';
import { FooterContent } from './components/footerConent';

import './consentManagementPage.scss';

export const ConsentManagementPage = () => {
    const { consentManagementStore } = useStore();
    const userConsents = consentManagementStore.userConsents;
    return useObserver(() => {
        return (<div className="consentManagementPage responsive-page-grid">
            <section className="responsive-page-content">
                <header className="content-header row no-gutters justify-content-center">
                    {userConsents.error && <LocalizedErrorFeedback errorKey={userConsents.error} />}
                    <HeaderContent />
                </header>
                <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                    <MainContent />
                </main>
                <footer className="content-footer transparent-lg-style row no-gutters justify-content-center pb-3">
                    <FooterContent />
                </footer>
            </section>
            <aside className="secondary-area" />
        </div>);
    });
};
