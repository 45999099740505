import 'reflect-metadata';
import { requiredMetadataKey } from './constants';

function getRequired<T, K extends keyof T>(target: T, propertyKey: K): IRequiredMetadata {
    return Reflect.getMetadata(requiredMetadataKey, target, propertyKey as string);
}

export interface IRequiredMetadata {
    errorMessageLabel: string;
}

export function required(args: IRequiredMetadata) {
    return Reflect.metadata(requiredMetadataKey, args);
}

export function hasRequired<T, K extends keyof T>(target: T, propertyKey: K): boolean {
    const meta = getRequired(target, propertyKey);
    return !!meta;
}

export function validateRequired<T, K extends keyof T>(target: T, propertyKey: K): string | null {
    const meta = getRequired(target, propertyKey);
    if (hasRequired(target, propertyKey)) {
        const value = target[propertyKey];
        if (!value) {
            return meta.errorMessageLabel;
        }
    }

    return null;
}