import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { IPasswordResetCallbackRouteParams, IPasswordResetRouteParams } from '../../common/stores/viewStore/contracts';
import { PasswordresetRequest } from './passwordResetRequest.model';
import { passwordResetStoreActions } from './passwordResetStore.actions';

const ChangeCompletedModel = types.model({
    changeSuccessful: types.optional(types.boolean, false),
    changeReceived: types.optional(types.boolean, false)
});

export const PasswordResetStore = types.model({
    changeCompletedModel: types.optional(ChangeCompletedModel, {}),
    passwordResetRequest: types.optional(PasswordresetRequest, {}),
    commonErrors: types.optional(types.array(types.string), [])
}).actions((self: any) => passwordResetStoreActions(self));

// TODO Refactor: Remove Model after store from name
export interface IPasswordResetStoreModel extends Instance<typeof PasswordResetStore> { }
export interface IPasswordResetStoreSnapshotOut extends SnapshotOut<typeof PasswordResetStore> { }
export interface IPasswordResetStoreSnapshotIn extends SnapshotIn<typeof PasswordResetStore> { }

export type IPasswordResetStoreActions = {
    initForPasswordResetRoute: (routeParams: IPasswordResetRouteParams) => void;
    initForPasswordCallbackRoute: (routeParams: IPasswordResetCallbackRouteParams) => void;
    startDataProcessing: () => void;
    stopDataProcessing: (erros?: string[]) => void;
    saveChangeRequest: () => Promise<boolean>;
};
