import i18n, { InitOptions } from 'i18next';

import { LangRessourcesService } from './frontend/common/services/langRessourcesService/langResourcesService';
import { CalingaBackend, CalingaBackendOptions, Cache } from 'i18next-calinga-backend';
import i18nLngDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import { I18nextAppLogger } from './i18nAppLogger';

// should we in futuer access the local storage from different places,
// create an adapter to ensure a namespace is always prvided
const namespaceCalingaStorage = 'calinga';

const cache: Cache = {
    read: async (key: string) => {
        try {
            const cachedItem = window.localStorage.getItem(`${namespaceCalingaStorage}-${key}`) || undefined as any;
            return cachedItem;
        } catch (e) {
            console.error('Could not retrive item from local storage', e);
            return undefined;
        }
    },
    write: async (key: string, value: string) => {
        try {
            window.localStorage.setItem(`${namespaceCalingaStorage}-${key}`, value);
        } catch (e) {
            console.error('Could not write item to local storage', e);
        }
    }
};

const calingaBackendOptions: CalingaBackendOptions = {
    team: 'AccessAndIdentity',
    cache,
    project: 'aiapp_default',
    organization: 'Abus'
};

const detectoptions: DetectorOptions = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'navigator', 'header'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next'
};

const langRessourcesService = new LangRessourcesService();
const appI18n = i18n;

const options: InitOptions = {
    detection: detectoptions,
    fallbackLng: 'en',
    backend: calingaBackendOptions,
    debug: false,
    appendNamespaceToMissingKey: true,
    ns: langRessourcesService.namespaces,
    whitelist: langRessourcesService.languages,
    nonExplicitWhitelist: false,
    load: 'languageOnly',
    defaultNS: 'aiapp_default',
    fallbackNS: 'aiapp_default',
    keySeparator: '',
    interpolation: {
        escapeValue: false,
        formatSeparator: ','
    },
    react: {
        wait: false,
        transSupportBasicHtmlNodes: true,
        bindI18n: 'loaded'
    } as any
};

appI18n.options = options;
appI18n.use(I18nextAppLogger);
appI18n.use(i18nLngDetector);
appI18n.use(CalingaBackend);

export default appI18n;
