import { toJS } from 'mobx';
import { CustomTypeOptions, types } from 'mobx-state-tree';

export const SimpleJsonType = <_T>() => {
    const jsonTypeOptions: CustomTypeOptions<Record<string, any>, any> = {
        name: 'jsonType',
        fromSnapshot: (snapshot) => snapshot,
        toSnapshot: (value) => toJS(value),
        getValidationMessage: (snapshot) => 'wrong type for error',
        isTargetType: (value) => true
    };
    return types.custom(jsonTypeOptions);
};