import moment from 'moment';
import { BaseRepository } from '../../common/baseRepository';
import * as BackendProxy from 'abus-cloud-access-identity-backend';
import { defaultLogger } from '../../../server/common/logging/defaultLogger';

export class PasswordResetRepository extends BaseRepository {
    private client: BackendProxy.PasswordApi;
    
    constructor() {
        super(); this.client = new BackendProxy.PasswordApi(this.bffProxyConfiguration);
    }

    public async changePassword(email: string, newPassword: string, language: string, appId: string): Promise<void> {
        const momentum = moment().format();
        try {
            await this.client.passwordControllerReset(
                {
                    mailAddress: email,
                    password: newPassword,
                    browserDatetime: momentum as any,
                    language,
                    appId
                });
            return;
        } catch (e) {
            defaultLogger.error('Api_PasswordReset Error', e);
            throw new Error('Api_PasswordReset');
        }
    }
}

export default PasswordResetRepository;