import { types } from 'mobx-state-tree';

export enum IRemoteDataSyncStates {
    Unintialized = 'Unintialized',
    Done = 'Done',
    LoadError = 'LoadError',
    SaveError = 'SaveError',
    Saving = 'Saving',
    Loading = 'Loading'
}

export enum IErrorType {
    Save = 'Save',
    Load = 'Load'
}

export const RemoteDataSyncStateModel = types.model({
    state: types.optional(types.enumeration<IRemoteDataSyncStates>(Object.values(IRemoteDataSyncStates)), IRemoteDataSyncStates.Unintialized),
    error: types.maybe(types.enumeration<IErrorType>(Object.values(IErrorType))),
    errorDetails: types.maybe(types.string)
})
    .views((self) => ({
        get isPostDataLoadedState(): boolean {
            return (self.state === IRemoteDataSyncStates.Done) ||
                (self.state === IRemoteDataSyncStates.Saving) ||
                (self.state === IRemoteDataSyncStates.SaveError);
        },
        get isDataLoadingState(): boolean {
            return (self.state === IRemoteDataSyncStates.Unintialized) ||
                (self.state === IRemoteDataSyncStates.Loading);
        }
    }))
    .actions((self) => ({
        reset() {
            self.state = IRemoteDataSyncStates.Unintialized;
            self.error = undefined;
            self.errorDetails = undefined;
        },
        setError(details?: string) {
            const isSavingState = self.state === IRemoteDataSyncStates.Saving;
            self.state = isSavingState ? IRemoteDataSyncStates.SaveError : IRemoteDataSyncStates.LoadError;
            self.error = isSavingState ? IErrorType.Save : IErrorType.Load;
            self.errorDetails = details;
        },
        setDone() {
            self.state = IRemoteDataSyncStates.Done;
            self.error = undefined;
            self.errorDetails = undefined;
        },
        setSyncing() {
            self.state = IRemoteDataSyncStates.Saving;
        },
        setLoading() {
            self.state = IRemoteDataSyncStates.Loading;
            self.error = undefined;
            self.errorDetails = undefined;
        }
    }));
