import * as React from 'react';
import { Trans } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { useStore } from '../../useStore';
import ConsentSection from './consentSection';
import NameRegistration from './namesSection';
import { CompanyIdSection } from './companyIdSection';
import EmailPasswordSection from './emailPasswordSection';

const I18NEXT_NAMESPACE = ['aiapp_register', 'aiapp_errors'];

const RegisterCustomer = () => {
    const { viewStore, registerStore } = useStore();

    return useObserver(() => {
        function validateAndRedirect() {
            const commonIsValid = registerStore.validateCommonModel();
            const customerIsValid = registerStore.customerRegistrationModel.validate();
            if (commonIsValid && customerIsValid) {
                viewStore.showCompanyProfileEditor();
            }
        }

        function renderLoginButton() {
            return (
                <button
                    key="0"
                    type="button"
                    onClick={() => viewStore.showLogin()}
                    className="btn btn-link btn-reg-switch"
                >
                    <span>__</span>
                </button>
            );
        }

        function renderHeaderRow() {
            return (<div className="row no-gutters justify-content-center">
                <div className="col-lg-12 mb-4">
                    {registerStore.isConsumerRegistration && <h6><Trans ns={I18NEXT_NAMESPACE}>RegistrationHeadline</Trans></h6>}
                    {!registerStore.isConsumerRegistration && <h6><Trans ns={I18NEXT_NAMESPACE}>CustomerRegistrationHeadline</Trans></h6>}
                </div>
            </div>);
        }

        function registrationButtonClicked() {
            registerStore.register();
        }

        function renderMainContentRow() {
            return (<div className="row no-gutters" >
                <div className="col-xl-6 col-lg-12 pr-xl-4">
                    <section className="customerIdRegistration">
                        <NameRegistration />
                        {!registerStore.isConsumerRegistration && <CompanyIdSection />}
                        <ConsentSection commonRegistrationModel={registerStore.commonRegistrationModel} />
                    </section>
                </div>
                <div className="col-xl-6 col-lg-12 pl-xl-4">
                    <EmailPasswordSection
                        emailPlaceholderKey={registerStore.isConsumerRegistration ? 'Email' : 'CompanyEmail'}
                        commonRegistrationModel={registerStore.commonRegistrationModel}
                    />
                </div>
            </div>);
        }

        function renderRegisterButton() {
            return (
                <button
                    className="btn btn-danger -btn-register w-100"
                    name="register"
                    onClick={() => registrationButtonClicked()}
                >
                    <Trans ns={I18NEXT_NAMESPACE}>Register</Trans>
                </button>);
        }

        function renderRedirectButton() {
            return (
                <button
                    className="btn btn-danger -btn-register w-100"
                    name="show-user-profile"
                    onClick={() => { validateAndRedirect() }}
                >
                    <Trans ns={I18NEXT_NAMESPACE}>Next</Trans>
                </button>);
        }

        function renderFooterRow() {
            return (<div className="container mt-4 no-gutters align-items-end ">
                <div className="row col-lg-6 pr-lg-3">
                    {registerStore.requiresCompanyProfile && renderRedirectButton()}
                    {!registerStore.requiresCompanyProfile && renderRegisterButton()}
                </div>
                <div className="row col-lg-6 mt-3">
                    <span className="page-switch-link">
                        <Trans
                            ns={I18NEXT_NAMESPACE}
                            i18nKey="Logintext"
                            components={[renderLoginButton()]}
                        />
                    </span>
                </div>
            </div>);
        }

        return (
            <div className={'registrationStep'}>
                {renderHeaderRow()}
                {renderMainContentRow()}
                {renderFooterRow()}
            </div>
        );
    });
};

export default RegisterCustomer;