import { autorun } from 'mobx';
import { compile, match } from 'path-to-regexp';
import { Location } from 'history';
import { IRootStore } from '../../../rootStore';
import { IView, IViewWInit, ViewNames, ViewService } from './viewService';
import { IViewStore, IViewStoreActions } from './viewStore';
import { getEnv, getRoot } from '../../utils/mobx';

const getDefaultViewConfig = (): IView => {
    return ViewService.getDefaultViewConfiguration(ViewNames.Login);
};

function viewGuard(hostType: string, view: IViewWInit<any> | IView, store: IViewStore): 'valid' | 'invalid' {
    if (view.navigatableFromHostLimited && hostType !== view.navigatableFromHostLimited) {
        store.currentView.name = ViewNames.NotFound;
        store.currentView.route = view.route;
        return 'invalid';
    }
    return 'valid';
}

export const viewStoreActions = (self: IViewStore): IViewStoreActions => {
    return {
        resolveLocationToView: async (location: Location) => {
            const view = ViewService.findViewByLocation(location) || getDefaultViewConfig();
            const matchFn = match(view.route, { decode: decodeURIComponent });
            const matchResult = matchFn(location.pathname + location.search);
            const params = typeof (matchResult) !== 'boolean' ? matchResult.params : {};
            await self.setView(view, params);
        },
        setView: async (view: IViewWInit<any> | IView, routeParams: Record<string, any> = {}) => {
            const { appConfig: { hostType }, isServer } = getEnv(self);

            const checkResult = viewGuard(hostType, view, self);
            if (checkResult === 'valid') {
                self.currentView.name = view.name;
                self.currentView.route = view.route;
                self.currentView.params = routeParams;
                if ((view as Partial<IViewWInit<any>>).onEnterFn) {
                    const viewWithInit = view as Partial<IViewWInit<any>>;
                    if ((isServer && viewWithInit.runEnterFnOnServer) || isServer === false) {
                        await (view as IViewWInit<any>).onEnterFn(getRoot(self), routeParams);
                    }
                }
            }
            self.setSyncLocationPathName(compile(view.route, { })(routeParams));
        },
        showRegister: () => {
            self.setView(ViewService.getDefaultViewConfiguration(ViewNames.Register));
        },
        showRegisterCustomer: () => {
            self.setView(ViewService.getDefaultViewConfiguration(ViewNames.RegisterCustomer));
        },
        showCompanyProfileEditor: () => {
            const rootStore: IRootStore = getRoot(self);
            rootStore.registerStore.companyProfileModel.clearErrors();
            self.setView(ViewService.getDefaultViewConfiguration(ViewNames.CompanyProfileEditor));
        },
        showLogin: () => {
            self.setView(ViewService.getDefaultViewConfiguration(ViewNames.Login));
        },
        showImprint: () => {
            self.setView(ViewService.getDefaultViewConfiguration(ViewNames.Imprint));
        },
        showPolicy: () => {
            self.setView(ViewService.getDefaultViewConfiguration(ViewNames.Policy));
        },
        lockUI: () => {
            self.countOfLockRequests = (self.countOfLockRequests + 1);
            return () => {
                self.unlockUI();
            };
        },
        setSyncLocationPathName: (path: string) => {
            self.syncLocationPathname = path;
        },
        initViewstore: async () => {
            const env = getEnv(self);

            await self.resolveLocationToView(env.history.location);
            
            if (!env.isServer) {
                env.history.listen((location, historyaction) => {
                    if ((location.pathname + location.search) !== self.syncLocationPathname) {
                        self.resolveLocationToView(location);
                    }
                });

                autorun(() => {
                    const path = self.syncLocationPathname;
                    if (path !== (env.history.location.pathname + env.history.location.search)) {
                        env.history.push(path);
                    }
                });
            }
        },
        unlockUI: () => {
            self.countOfLockRequests = (self.countOfLockRequests - 1);
        },
        showPasswordReset: (appId: string, email?: string) => {
            const routeParameters = {};
            if (email) {
                Object.assign(routeParameters, { email });
            }
            Object.assign(routeParameters, { appId });

            self.setView(ViewService.getDefaultViewConfiguration(ViewNames.PasswordReset), routeParameters);
        },
        showVerifymailResendCompleted: () => {
            self.setView(ViewService.getDefaultViewConfiguration(ViewNames.VerifymailResendCompleted));
        },
        exit: (exitUrl) => {
            window.location.assign(exitUrl);
        }
    };
};