import { types } from 'mobx-state-tree';

export const NumericEnumType = <T extends number>(allowedValues: any[]) => {
    const allowedValuesFiltered = allowedValues.filter((x) => typeof x === 'number').map((x: number) => x.toString());
    return types.custom<string, T>({
        name: 'NumericEnum',
        fromSnapshot(s: string) {
            return Number(s) as T;
        },
        toSnapshot(val: T) {
            return val.toString();
        },
        isTargetType(valueOrSnapshot: string | T) {
            return allowedValues.includes(valueOrSnapshot);
        },
        getValidationMessage(snapshot: string) {
            if (!allowedValuesFiltered.map((x) => x).includes(snapshot)) {
                return 'Does not look like a valid enum value';
            }
            return '';
        }
    });
};