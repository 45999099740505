import { Auth0ErrorTypes, Auth0Service } from '../common/services/auth0Service';
import { FedConnectionTypes } from '../common/services/auth0Service/fedConnectionTypes';
import { getEnv, getRoot } from '../common/utils/mobx';
import { ValidatonService } from '../common/validation';
import { ILoginStore, ILoginStoreActions } from './loginStoreModel';
import { LoginValidationModel } from './LoginValidationModel';
import { PasswordChangeRedirectValidationModel } from './PasswordChangeRedirectValidationModel';

export const loginStoreActions = (self: ILoginStore): ILoginStoreActions => {
    return {
        clearErrors: () => {
            self.emailErrors.replace([]);
            self.commonErrors.replace([]);
            self.passwordErrors.replace([]);
        },

        setError: (errorcode: string) => {
            self.clearErrors();
            const { authZeroConfig } = getEnv(self);
            const _auth0Service = new Auth0Service(authZeroConfig);
            const target = _auth0Service.getAuth0ErrorType(errorcode);
            if (target === Auth0ErrorTypes.emailRelated) {
                self.emailErrors.replace([errorcode]);
            } else if (target === Auth0ErrorTypes.passwordRelated) {
                self.passwordErrors.replace([errorcode]);
            } else {
                self.commonErrors.replace([errorcode]);
            }
        },

        updateEmail: (value: string) => {
            self.email = value;
        },

        updatePassword: (value: string) => {
            self.password = value;
        },

        login: async () => {
            const validationService = new ValidatonService();
            const errors = validationService.validate(new LoginValidationModel(self.email, self.password));
            if (!errors) {
                const rootStore = getRoot(self);
                const unlockFn = rootStore.viewStore.lockUI();
                try {
                    const { authZeroConfig } = getEnv(self);
                    const auth0Service = new Auth0Service(authZeroConfig);
                    await auth0Service.login(self.email, self.password);
                    return;
                } catch (errorcode) {
                    self.setError(errorcode);
                } finally {
                    unlockFn();
                }
            } else {
                self.clearErrors();
                self.emailErrors.replace(errors.email || []);
                self.passwordErrors.replace(errors.password || []);
            }
        },

        loginfed: async () => {
            const { authZeroConfig } = getEnv(self);
            const doLogin = (type: FedConnectionTypes) => {
                const rootStore = getRoot(self);
                const unlockFn = rootStore.viewStore.lockUI();
                try {
                    const auth0Service = new Auth0Service(authZeroConfig);
                    auth0Service.loginFed(type);
                } finally {
                    unlockFn();
                }
            };

            if (self.email.toLowerCase().endsWith('@conplement.de')) {
                doLogin(FedConnectionTypes.Conplement);
            } else if (self.email.toLowerCase().endsWith('@abus-sc.com')) {
                doLogin(FedConnectionTypes.Abus);
            }
        },

        showPasswordReset: () => {
            let email: string | undefined;
            const errors = new ValidatonService().validate(new PasswordChangeRedirectValidationModel(self.email));
            if (!errors) {
                email = self.email;
            }

            const { authZeroConfig } = getEnv(self);
            const rootStore = getRoot(self);
            rootStore.viewStore.showPasswordReset(authZeroConfig.clientID, email);
        }
    };
};
