import { types, Instance, SnapshotIn } from 'mobx-state-tree';
import { viewStoreActions } from './viewStoreActions';
import { IView, IViewWInit } from './viewService';
import { Location } from 'history';
import { getEnv } from '../../utils/mobx';
import { FeaturesService } from '../../services/featuresService';
import { userModelActions } from './userModelActions';
import { SimpleJsonType } from '../simpleJsonType';

export enum TokenScopes {
  None = 'None',
  Consent = 'Consent',
  Unverified = 'Unverified'
}

export const UserModel = types.model({
    accessToken: types.maybeNull(types.string),
    tokenScope: types.maybeNull(types.enumeration<TokenScopes>(Object.values(TokenScopes)))
}).actions((self: any) => userModelActions(self));

export const FeaturesModel = types.model({}).views((self) => ({
    get registrationEnabled(): boolean {
        return FeaturesService.registrationEnabled;
    }
}));
export const ViewStore = types.model({
    countOfLockRequests: types.optional(types.number, 0),
    user: UserModel,
    features: FeaturesModel,
    currentView: types.model({
        name: types.string,
        route: types.string,
        params: SimpleJsonType<any>()
    }),
    syncLocationPathname: types.optional(types.string, '')
}).views((self) => ({
    get lockedUI(): boolean {
        return self.countOfLockRequests !== 0;
    },
    get auth0configurationClientId(): string | null {
        const { authZeroConfig } = getEnv(self);
        return authZeroConfig?.clientID;
    },
    get auth0configurationTitle(): string | null {
        const { authZeroConfig } = getEnv(self);
        return authZeroConfig?.dict?.signin?.title;
    }
})
).actions((self: any) => viewStoreActions(self));

export interface IViewStore extends Instance<typeof ViewStore> { }
export interface IViewStoreSnapshotIn extends SnapshotIn<typeof ViewStore> { }
export type IViewStoreActions = {
  resolveLocationToView: (historylocation: Location) => Promise<void>;
  setView: (view: IView | IViewWInit<any>, routeParams?: Record<string, any>) => Promise<void>;
  showRegister: () => void;
  showRegisterCustomer: () => void;
  showCompanyProfileEditor: () => void;
  showLogin: () => void;
  showPasswordReset: (appId: string, email?: string) => void;
  showImprint: () => void;
  showPolicy: () => void;
  lockUI: () => () => void;
  initViewstore: () => Promise<void>;
  unlockUI: () => void;
  showVerifymailResendCompleted: () => void;
  exit: (exitUrl: string) => void;
  setSyncLocationPathName(path: string): void;
};

export interface IUserModel extends Instance<typeof UserModel> { }
export interface IUserModelSnapshotIn extends SnapshotIn<typeof UserModel> { }
export type IUserModelActions = {
  setToken(token: string, scope: TokenScopes): void;
};