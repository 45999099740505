import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import './policy.scss';
import ReactMarkdown from 'react-markdown';

class Policy extends React.Component<WithTranslation, any> {
    public render() {
        const { t } = this.props;
        return (
            <div className="policy">
                <div className="container policy-mwidth pt-5">
                    <section className="">
                        <h2 className="typo-h">{t('Policy.Heading')}</h2>
                        <ReactMarkdown className="m-0 typo-c1">{t('Policy.Body')!}</ReactMarkdown>
                    </section>
                </div>
            </div>
        );
    }
}

export default withTranslation('aiapp_default')(Policy);