export const buildLabel = (baseparts: string[], add?: string) => {
    const parts = baseparts.slice(0);
    if (add && add !== '') {
        let a = add;
        if (a.endsWith('.')) {
            a = a.substr(0, a.length - 1);
        }
        parts.push(a);
    }
    return parts.join('.');
};

export const getLabelsByMessage = (success: boolean, baseLabelPart: string, successLabelPart: string,
    failLabelPart: string, message: string | undefined, defaultLabel: string): string[] => {
    const labelparts = [baseLabelPart];
    const labels = [];

    if (success) {
        labelparts.push(successLabelPart);
    } else {
        labelparts.push(failLabelPart);
    }

    if (message) {
        labels.push(buildLabel(labelparts, message));
    }

    labels.push(buildLabel(labelparts, defaultLabel));

    return labels;
};