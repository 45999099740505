import 'reflect-metadata';
import { patternMetadataKey } from './constants';

export interface IPatternMetadata {
    pattern: string|RegExp;
    errorMessageLabel: string;
}

function getPattern<T, K extends keyof T>(target: T, propertyKey: K): IPatternMetadata {
    return Reflect.getMetadata(patternMetadataKey, target, propertyKey as string);
}

export function pattern(args: IPatternMetadata) {
    return Reflect.metadata(patternMetadataKey, args);
}

export function hasPattern<T, K extends keyof T>(target: T, propertyKey: K): boolean {
    const meta = getPattern(target, propertyKey);
    return meta && !!meta.pattern;
}

export function validatePattern<T, K extends keyof T>(target: T, propertyKey: K): string | null {
    const patternMeta = getPattern(target, propertyKey);
    if (hasPattern(target, propertyKey)) {
        const value: string = target[propertyKey] as any;
        const reg = new RegExp(patternMeta.pattern);
        const result = reg.exec(value);
        if (!result) {
            return patternMeta.errorMessageLabel;
        }
    }

    return null;
}