import { isEmpty } from 'class-validator';
import { RegistrationModel } from './commonRegistrationModel';
import { ExtendedRegistrationModel } from './extendedRegistrationModel';
import { CompanyProfileModel } from './companyProfileModel';
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import * as registerStoreActions from './registerStoreActions';
import { RegistrationTypes } from '../../common';
import { NumericEnumType } from '../../common/stores/numericEnumType';

export const RegisterStore = types.model({
    commonRegistrationModel: types.optional(RegistrationModel, {}),
    customerRegistrationModel: types.optional(ExtendedRegistrationModel, {}),
    companyProfileModel: types.optional(CompanyProfileModel, {}),
    registrationType: types.optional(NumericEnumType<RegistrationTypes>(Object.values(RegistrationTypes)), RegistrationTypes.consumer)
}).views((self) => ({
    get isEmailAndPasswordEmpty() {
        return isEmpty(self.commonRegistrationModel.email) &&
      isEmpty(self.commonRegistrationModel.password);
    },
    get isConsumerRegistration() {
        return self.registrationType === RegistrationTypes.consumer;
    },
    get requiresCompanyProfile() {
        return self.registrationType === RegistrationTypes.customer && !self.customerRegistrationModel.customerWithCustomerID;
    }
})).actions((self: any) => ({
    setRegistrationError: registerStoreActions.setRegistrationError(self),
    validateCustomerRegistration: registerStoreActions.validateCustomerRegistration(self),
    validateCommonModel: registerStoreActions.validateCommonModel(self),
    register: registerStoreActions.register(self),
    validatePasswordWasRepeated: registerStoreActions.validatePasswordWasRepeated(self),
    clearBasicRegistrationErrors: registerStoreActions.clearBasicRegistrationErrors(self),
    changeRegistrationType: registerStoreActions.changeRegistrationType(self)
}));

export interface IRegisterStore extends Instance<typeof RegisterStore> { }
export interface IRegisterStoreSnapshotOut extends SnapshotOut<typeof RegisterStore> { }
export interface IRegisterStoreSnapshotIn extends SnapshotIn<typeof RegisterStore> { }