import React from 'react';
import { useTranslation } from 'react-i18next';

import './error.scss';

export const NotFound = () => {
    const { t } = useTranslation();
    return (
        <div className="container error-component">
            <div className="general-error-background-overlay" />
            <div className="error-wrapper col-md-7 text-left">
                <div className="row row-item">
                    <div className="error-header">
                        <h2>{t('Error.404.Header')}</h2>
                    </div>
                </div>

                <div className="row row-item">
                    <div className="error-title">
                        <h5>{t('Error.404.Title')}</h5>
                    </div>
                </div>

                <div className="row row-item">
                    <div className="error-message">
                        <h6>{t('Error.404.Message')}</h6>
                    </div>
                </div>

                <div className="row row-item">
                    <div className="error-hints">
                        <div className="error-hint d-flex">
                            <i className="icon-icon_control_arrow_right" />
                            <span>{t('Error.Hint.CheckURL')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};