export enum ServerRoutes {
    CallbackLogin = '/callback',
    CallbackVerifymail = '/callbackverifymail',
    CallbackResetPassword = '/callbackresetpassword',
    ResendVerification = '/actions/verificationresend',
    ApplicationHome = '/actions/applicationhome',
    HealthCheck = '/healthz',
    ApiDocumentation = '/api-docs',
    Logout = '/logout',
    StartLogin = '/login',
    Any = '/*'
}
