import * as React from 'react';

import './errorFeedbackLine.scss';

// tslint:disable-next-line: no-empty-interface
export interface IFeedbackProps {
    className?: string;
}

export class ErrorFeedbackLine extends React.Component<IFeedbackProps> {
    public render() {
        const props = {
            className: `event w-100 d-block ${this.props.className || ''}`,
            ...this.props
        };
        return (
            <div {...props}>
                {this.props.children}
            </div>
        );
    }
}
