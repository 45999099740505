import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorFeedbackLine } from './feedback';

export const LocalizedErrorFeedback = (props: { errorKey: string }) => {
    const [t] = useTranslation('aiapp_errors');
    return (
        <ErrorFeedbackLine key={props.errorKey}>
            {t(props.errorKey)}
        </ErrorFeedbackLine>
    );
};