import * as React from 'react';

import './passwordRepeat.scss';
import Input from '../input';
import { observer } from 'mobx-react';

export interface IPasswordRepeatProps {
  passwordErrorMessages: string[];
  passwordConfiramtionErrorMessages: string[];
  passwordPlaceholder: string;
  passwordConfirmationPlaceholder: string;
  surpressErrorText?: boolean;
  onChange?: (password: string, isConfirmed: boolean) => void;
}

export interface IPasswordRepeatState {
  password: string;
  repeatedPassword: string;
  errors: string[];
}

@observer
class PasswordRepeat extends React.Component<IPasswordRepeatProps, IPasswordRepeatState> {
    public getInitialState(): IPasswordRepeatState {
        return {
            password: '',
            repeatedPassword: '',
            errors: []
        };
    }

    constructor(props: IPasswordRepeatProps) {
        super(props);
        this.state = this.getInitialState();
        this.updateValue = this.updateValue.bind(this);
    }

    private updateValue(elementName: any, newValue: string) {
        if (elementName === 'password') {
            this.setState({ password: newValue }, this.validatePasswordAndNotify);
        } else if (elementName === 'repeatedPassword') {
            this.setState({ repeatedPassword: newValue }, this.validatePasswordAndNotify);
        }
    }

    private validatePasswordAndNotify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.password, this.isPasswordConfirmed());
        }
    }

    private isPasswordConfirmed(): boolean {
        return this.state.password !== '' && this.state.repeatedPassword !== '' && this.state.password === this.state.repeatedPassword;
    }

    private getErrors(): string[] {
        if ((this.state.password === '' && this.state.repeatedPassword === '') || this.isPasswordConfirmed()) {
            return [];
        } else {
            return this.props.passwordConfiramtionErrorMessages;
        }
    }

    public render() {
        return (
            <section>
                <div className="row">
                    <div className="col-12">
                        <Input
                            name="password"
                            type="password"
                            surpressErrorText={this.props.surpressErrorText}
                            placeholder={this.props.passwordPlaceholder}
                            value={this.state.password}
                            errorMessage={this.props.passwordErrorMessages}
                            onChange={this.updateValue}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Input
                            name="repeatedPassword"
                            type="password"
                            surpressErrorText={this.props.surpressErrorText}
                            value={this.state.repeatedPassword}
                            placeholder={this.props.passwordConfirmationPlaceholder}
                            errorMessage={this.getErrors()}
                            onChange={this.updateValue}
                        />
                    </div>
                </div>
            </section>
        );
    }
}

export default PasswordRepeat;