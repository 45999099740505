import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { IVerificationMailResendCompletedParams, IVerifyMailCallbackParams } from '../common/stores/viewStore/contracts';
import { getLabelsByMessage } from './buildLabel';
import { verifymailStoreActions } from './verifymailStoreActions';

export const VerifymailStore = types.model({
    changeSuccessful: types.optional(types.boolean, false),
    changeReceived: types.optional(types.boolean, false),
    showSuccessConfirmation: types.optional(types.boolean, true),
    message: types.optional(types.string, ''),
    sendEnabled: types.optional(types.boolean, true),
    secondsToUnlock: types.optional(types.number, 0)
}).views((self) => ({
    get headlineLabel() {
        return getLabelsByMessage(self.changeSuccessful, 'VerifymailCallback', 'HeadlineSuccess', 'HeadlineFail', self.message, 'Default');
    },
    get titleLabel() {
        return getLabelsByMessage(self.changeSuccessful, 'VerifymailCallback', 'TitleSuccess', 'TitleFail', self.message, 'Default');
    },
    get textLabel() {
        return getLabelsByMessage(self.changeSuccessful, 'VerifymailCallback', 'TextSuccess', 'TextFail', self.message, 'Default');
    },
    get infoLabel() {
        return getLabelsByMessage(self.changeSuccessful, 'VerifymailCallback', 'InfoSuccess', 'InfoFail', self.message, 'Default');
    }
})).actions(
    (self: any) => verifymailStoreActions(self)
);

export interface IVerifymailStore extends Instance<typeof VerifymailStore> { }
export interface IVerifymailStoreSnapshotOut extends SnapshotOut<typeof VerifymailStore> { }
export interface IVerifymailStoreSnapshotIn extends SnapshotIn<typeof VerifymailStore> { }

export type IVerifymailStoreActions = {
    initializeForVerifymailCompletedRoute: (params: IVerifyMailCallbackParams) => void;
    initializeForVerifymailResendCompletedRoute: (params: IVerificationMailResendCompletedParams) => void;
    startReenableTimer: () => void;
    checkShouldUnlock: () => void;
    startVerificationResendFlow: () => void;
};