import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationHomeButton } from '../common/components/button/applicationHomeButton';
import { useStore } from '../useStore';
import { I18nAreaNamespace } from './index';

import './passwordResetPage.scss';

export const PasswordResetCallbackPage = () => {
    const [t] = useTranslation(I18nAreaNamespace);
    const { passwordResetStore } = useStore();
    const success = passwordResetStore.changeCompletedModel.changeSuccessful;

    return (
        <div className="passwordResetPage responsive-page-grid">
            <section className="responsive-page-content">
                <header className="content-header row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <span className="vertical-heading-decorator">
                            <span className="typo-s2">
                                {success ? t('PasswordResetCallback.HeadlineSuccess') : t('PasswordResetCallback.HeadlineFail')}
                            </span>
                            <h2>
                                {t('PasswordResetCallback.Title')}
                            </h2>
                        </span>
                    </div>
                </header>
                <main className="content-main transparent-lg-style row no-gutters justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <div className="row">
                            <div className={`col-2 justify-content-center align-items-start callbackfeedback ${success ? 'success' : 'failure'}`}>
                                <div className="icon-icon_control_confirm" />
                            </div>
                            <div className={`align-items-center col-6 callbackfeedback ${success ? 'success' : 'failure'}`}>
                                <p className="typo-c1">{t(success ? 'PasswordResetCallback.TextSuccess' : 'PasswordResetCallback.TextFail')}</p>
                            </div>
                        </div>
                        <div className="row justify-content-start mt-4">
                            <p className="typo-c1 col-8">
                                {t(success ? 'PasswordResetCallback.InfoSuccess' : 'PasswordResetCallback.InfoFail')}
                            </p>
                        </div>
                        <div className="row justify-content-start">
                            <p className="typo-c1 col-8">{t('PasswordResetCallback.Farewell')}</p>
                        </div>
                        <div className="row justify-content-start">
                            <ApplicationHomeButton />
                        </div>
                    </div>
                </main>
                <footer className="content-footer transparent-lg-style" />
            </section>
            <aside className="secondary-area" />
        </div>);
};