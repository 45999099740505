import { getSnapshot, castToSnapshot, flow } from 'mobx-state-tree';
import { IConsentManagementParams } from '../../common/stores/viewStore/contracts';
import { getEnv, getRoot } from '../../common/utils/mobx';
import { IConsentManagementStore, IConsentManagementStoreActions } from './consentManagementStore';
import { ConsentRepository } from './consentRepository';
import { IConsents } from './consents.interface';

export const consentStoreActions = (self: IConsentManagementStore): IConsentManagementStoreActions => {
    return {
        initializeForConsentManagementRoute: async (params: IConsentManagementParams) => {
            await self.load();
        },
        load: flow(function * () {
            try {
                self.userConsents.setLoading();

                const result = new ConsentRepository(getRoot(self).viewStore.user.accessToken!).getCurrent();
                const model = yield result;
                self.userConsents = castToSnapshot({
                    ...getSnapshot(self.userConsents),
                    ...model
                });
                self.userConsents.setDone();
            } catch (e) {
                self.userConsents.setError();
                throw e;
            }
        }),
        saveConsents: flow(function * () {
            const { appConfig } = getEnv(self);
            for (const consent of self.userConsents.consents) {
                consent.validate();
            }
            const allValid = self.userConsents.requiredConsentsGiven;
            if (allValid) {
                const { viewStore } = getRoot(self);
                const lock = viewStore.lockUI();
                try {
                    self.userConsents.setSyncing();
                    const consentSnapshot = getSnapshot(self.userConsents);
                    const result = new ConsentRepository(getRoot(self).viewStore.user.accessToken!).save(castToSnapshot(consentSnapshot));
                    yield result;
                    getRoot(self).viewStore.exit(`https://${appConfig.auth0domain}/continue${window.location.search}`);
                    self.userConsents.setDone();
                } catch (e) {
                    self.userConsents.setError();
                    throw e;
                } finally {
                    lock();
                }
            }
        }),
        changeConsent: async (consentType: IConsents, consentGiven: boolean) => {
            const consent = self.userConsents.consents.find((x) => x.consentType === consentType)!;
            consent.setConsent(consentGiven);
        }
    };
};
