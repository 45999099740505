import React from 'react';
import { IRootStore } from './rootStore';

export const StoreContext = React.createContext<IRootStore | null>(null);

export const useStore = () => {
    const store = React.useContext(StoreContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useStore must be used within a StoreProvider.');
    }
    return store;
};