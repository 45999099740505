import { IExtendedRegistrationModelActions } from './companyProfileModel';
import { CompanyProfileVerificationModel } from './verification/companyProfileVerificationModel';

export const companyProfileActions = (self: any): IExtendedRegistrationModelActions => {
    return ({
        updateValue: (elementName, newValue) => {
            self[elementName as string] = newValue;
        },
        clearErrors: () => {
            self.errors.clear();
        },
        validate: () => {
            const v = new CompanyProfileVerificationModel();
            v.companyName = self.companyName;
            v.street = self.street;
            v.houseNumber = self.houseNumber;
            v.country = self.country;
            v.city = self.city;
            v.postalCode = self.postalCode;
            v.taxId = self.taxId;
            v.phone = self.phone;
            const result = v.validate();
            (self.errors as any) = [];
            const keys = Object.keys(v.errors);
            keys.forEach((key) => {
                self.errors.push({ field: key, errors: v.errors[key] });
            });
            return result;
        }
    });
};