import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { IConsentManagementParams } from '../../common/stores/viewStore/contracts';
import { RemoteDataSyncStateModel } from '../../common/stores/remoteDataSyncStateModel';
import { consentStoreActions } from './consentManagementStoreActions';
import { ConsentModel } from './consentModel';
import { IConsents } from './consents.interface';

export const UserConsetsModel = types.model({
    applicationName: types.optional(types.string, ''),
    applicationId: types.optional(types.string, ''),
    version: types.optional(types.number, 1),
    consents: types.optional(types.array(ConsentModel), [])
}).views((self) => ({
    get requiredConsentsGiven(): boolean {
        return self.consents.every((x) => x.isOptional || x.consentGiven === true);
    }
}));

export const ConsentManagementStore = types.model({
    userConsents: types.optional(types.compose(RemoteDataSyncStateModel, UserConsetsModel), {})
})
    .actions((self: any) => consentStoreActions(self));

export interface IConsentManagementStore extends Instance<typeof ConsentManagementStore> { }
export interface IConsentManagementStoreSnapshotIn extends SnapshotIn<typeof ConsentManagementStore> { }
export interface IConsentManagementStoreSnapshotOut extends SnapshotOut<typeof ConsentManagementStore> { }

export type IConsentManagementStoreActions = {
  initializeForConsentManagementRoute: (params: IConsentManagementParams) => void;
  changeConsent: (consentType: IConsents, consentGiven: boolean) => Promise<void>;
  load: () => Promise<void>;
  saveConsents: () => Promise<void>;
};
