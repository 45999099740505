import * as React from 'react';
import ProfileForm from './profileForm/profileForm';

import './registerPage.scss';

const CompanyProfilePage = () => {
    return (
        <div className="registerPage responsive-page-grid rpg-collapse-md no-background">
            <ProfileForm className="responsive-page-content" />
            <aside className="secondary-area" />
        </div>
    );
};
export default CompanyProfilePage;