import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { ErrorsModel } from '../../common/stores/errorModel';
import { passwordResetRequestActions } from './passwordResetRequest.actions';

export const PasswordresetRequest = types.model({
    appId: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    isEMailReadonly: types.optional(types.boolean, false),
    password: types.optional(types.string, ''),
    passwordrepeat: types.optional(types.string, ''),
    isSaved: types.optional(types.boolean, false),
    errors: types.optional(ErrorsModel, {}),
    language: types.optional(types.string, 'en')
}).actions((self: any) => passwordResetRequestActions(self));

export interface IPasswordresetRequestModel extends Instance<typeof PasswordresetRequest> { }
export interface IPasswordresetRequestSnapshotOut extends SnapshotOut<typeof PasswordresetRequest> { }
export interface IPasswordresetRequestnapshotIn extends SnapshotIn<typeof PasswordresetRequest> { }

export type IPasswordresetRequestActions = {
    update: (delta: IPasswordresetRequestnapshotIn) => void;
    validate: () => boolean;
    validateSingle: <T extends keyof IPasswordresetRequestnapshotIn>(name: T) => void;
    validateAndSave: () => Promise<boolean>;
};
