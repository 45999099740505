import React from 'react';
import { Trans, TransProps, useTranslation } from 'react-i18next';

const bindTrans = (namespaces: string | string[]) => {
    const { t } = useTranslation(namespaces);
    const BoundTrans = (props: TransProps) => {
        return <Trans t={t} {...props} />;
    };

    return BoundTrans;
};

export default bindTrans;