export type BROWSERSTACKLOCAL_VALUES = '1' | '0';
export type NODEENV_VALUES = 'production' | 'development';
export type APP_HOST_TYPE = 'staticpage' | 'nodejs';
// ****** HOW FLAGS ARE USED IN TS/JS ******
// https://basarat.gitbook.io/typescript/type-system/enums#number-enums-as-flags
export enum IDISABLED_FEATURES_FLAGS {
    None = 0,
    Registration = 1 << 0
}
export interface IEnvConfig {
    BFF_BASE_URL: string;
    APIGATEWAY: {
        BASEURL: string;
        APIKEY: string;
    };
    AUTH0: {
        APP: {
            CLIENTID: string;
            CLIENT_SECRET: string;
            CALLBACK_URL: string;
            DOMAIN: string;
            AUDIENCE: string;
        }
        MANAGEMENTAPI: {
            CLIENTID: string;
            CLIENT_SECRET: string;
            DOMAIN: string;
            AUDIENCE: string;
            CONNECTION_ID: string;
        },
        PASSWORDRESET: {
            TTLSECONDS: number;
        }
    };
    MAIL: {
        SMTP_PASSWORD: string;
        SMTP_USER: string;
        SMTP_FROM: string;
        SMTP_HOSTNAME: string;
        SMTP_PORT: number;
    };
    TEMPLATE_SERVICE: {
        BASE_URL: string
    };
    USER_SERVICE: {
        RATELIMIT: {
            WINDOW_SECONDS: number;
            LIMIT: number;
        }
    };
    APP_HOST: APP_HOST_TYPE;
    CORS_ALLOWEDORIGIN: string;
    PORT: string | number;
    LOG_LEVEL: string;
    LOG_ENABLED: boolean;
    DISABLED_FEATURES_FLAG: IDISABLED_FEATURES_FLAGS;
    NODE_ENV: NODEENV_VALUES; // will be set from node during startup
    ENV: {
        UI: {
            NAME: string | undefined;
            COLOR: string | undefined;
            LOGO: string | undefined;
        }
    };
    CACHING: {
        CACHE_LOWRATEREFRESH_SECONDS: number;
        CACHE_REFRESH_SECONDS: number;
        REDIS_ENDPOINT: string;
        REDIS_PORT: number;
    };
}

export const getEnvConfig = (): IEnvConfig => {
    const readEnvAsInteger = (envsetting: string): number | undefined => {
        const setting = process.env[envsetting];
        const value = setting && Number.isInteger(Number(setting)) ? Number(setting) : undefined;
        return value;
    };

    const settings: IEnvConfig = {
        DISABLED_FEATURES_FLAG: readEnvAsInteger('DISABLED_FEATURES_FLAG') ?? IDISABLED_FEATURES_FLAGS.None,
        BFF_BASE_URL: process.env.BFF_BASE_URL || 'http://localhost:3100',
        APIGATEWAY: {
            BASEURL: process.env.APIGATEWAY_BASEURL!,
            APIKEY: process.env.APIGATEWAY_APIKEY!
        },
        CORS_ALLOWEDORIGIN: process.env.CORS_ALLOWEDORIGIN || '',
        PORT: process.env.APP_PORT || 3100,
        LOG_LEVEL: process.env.PORTAL_LOG_LEVEL || 'verbose',
        LOG_ENABLED: process.env.PORTAL_LOG_ENABLED !== '0',
        NODE_ENV: process.env.NODE_ENV as NODEENV_VALUES || 'development',
        AUTH0: {
            APP: {
                DOMAIN: process.env.AUTH0_DOMAIN!,
                AUDIENCE: process.env.AUTH0_AUDIENCE!,
                CLIENTID: process.env.AUTH0_CLIENTID!,
                CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET!,
                CALLBACK_URL: process.env.AUTH0_CALLBACK_URL!
            },
            MANAGEMENTAPI: {
                CLIENTID: process.env.AUTH0_MANAGEMENTAPI_CLIENTID!,
                CLIENT_SECRET: process.env.AUTH0_MANAGEMENTAPI_CLIENTSECRET!,
                DOMAIN: process.env.AUTH0_MANAGEMENTAPI_DOMAIN!,
                AUDIENCE: process.env.AUTH0_MANAGEMENTAPI_AUDIENCE!,
                CONNECTION_ID: process.env.AUTH0_DATABASE_CONNECTION_ID!
            },
            PASSWORDRESET: {
                TTLSECONDS: readEnvAsInteger('AUTH0_PASSWORDRESET_TTLSECONDS') || 360
            }
        },
        MAIL: {
            SMTP_PASSWORD: process.env.SMTP_ABUS_AUTH0_PASSWORD!,
            SMTP_USER: process.env.SMTP_ABUS_AUTH0_USER!,
            SMTP_FROM: process.env.SMTP_FROM!,
            SMTP_HOSTNAME: process.env.SMTP_HOSTNAME!,
            SMTP_PORT: readEnvAsInteger('SMTP_PORT') || 10027
        },
        TEMPLATE_SERVICE: {
            BASE_URL: process.env.TEMPLATE_SERVICE_BASE_URL!
        },
        USER_SERVICE: {
            RATELIMIT: {
                LIMIT: readEnvAsInteger('USER_SERVICE_RATELIMIT_LIMIT') || 2,
                WINDOW_SECONDS: readEnvAsInteger('USER_SERVICE_RATELIMIT_WINDOW_SECONDS') || 600
            }
        },
        ENV: {
            UI: {
                NAME: process.env.ENV_UISTAMP_NAME,
                COLOR: process.env.ENV_UISTAMP_COLOR,
                LOGO: process.env.ENV_UISTAMP_LOGO
            }
        },
        CACHING: {
            CACHE_LOWRATEREFRESH_SECONDS: readEnvAsInteger('CACHING_CACHE_LOWRATEREFRESH_SECONDS') || 7200,
            CACHE_REFRESH_SECONDS: readEnvAsInteger('CACHING_CACHE_REFRESH_SECONDS') || 600,
            REDIS_ENDPOINT: process.env.CACHING_REDIS_ENDPOINT || 'redis',
            REDIS_PORT: readEnvAsInteger('CACHING_REDIS_PORT') || 6379
        },
        APP_HOST: process.env.APP_HOST as APP_HOST_TYPE || 'nodejs'
    };

    return settings;
};
