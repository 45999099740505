import { FedConnectionTypes } from './fedConnectionTypes';

export const CONPLEMENT_WAAD = 'conplement-waad';

export const ABUS_WAAD = 'abus-waad';

export default function fedConnectionSelector(connection: FedConnectionTypes): string {
    switch (connection) {
    case FedConnectionTypes.Conplement:
        return CONPLEMENT_WAAD;
    case FedConnectionTypes.Abus:
    default:
        return ABUS_WAAD;
    }
}