import { ConsentDto, Consents, ConsentsApi, UpdateUserConsentsDto, UserConsentsDto } from 'abus-cloud-access-identity-backend';
import { SnapshotOut } from 'mobx-state-tree';
import { BaseRepository } from '../../common/baseRepository';
import { swapObjectKeyValues } from '../../common/utils/swapObjectKeyValues';
import { UserConsetsModel } from './consentManagementStore';
import { ConsentModel } from './consentModel';
import { IConsents } from './consents.interface';

type ModelSnapshot = SnapshotOut<typeof UserConsetsModel>;
export class ConsentRepository extends BaseRepository {
    constructor(private token: string) {
        super();
    }

    public async getCurrent(): Promise<ModelSnapshot> {
        try {
            const api = new ConsentsApi({ ...this.bffProxyConfiguration, accessToken: this.token });
            const consentsResponse = await api.consentsControllerGetCurrent();
            return this.mapToUserConsentsModel(consentsResponse.data);
        } catch (e) {
            throw new Error(e);
        }
    }

    public async save(userConsents: ModelSnapshot): Promise<void> {
        try {
            const api = new ConsentsApi({ ...this.bffProxyConfiguration, accessToken: this.token });
            await api.consentsControllerSave(this.mapToUserConsentsDto(userConsents));
            return;
        } catch (e) {
            throw new Error(e);
        }
    }

    private mapToUserConsentsDto(snapshot: ModelSnapshot): UpdateUserConsentsDto {
        return {
            applicationId: snapshot.applicationId,
            version: snapshot.version,
            consents: snapshot.consents.map((cs) => ({ consentGiven: cs.consentGiven, isOptional: cs.isOptional, consentType: this.mapFrontendConsentTypeToApi(cs.consentType) }))
        };
    }

    private mapToUserConsentsModel(dto: UserConsentsDto): ModelSnapshot {
        return {
            applicationId: dto.applicationId,
            applicationName: dto.applicationName,
            consents: dto.consents.map((c) => this.mapToConsentModel(c)),
            version: dto.version!
        };
    }

    private mapToConsentModel(dto: ConsentDto): SnapshotOut<typeof ConsentModel> {
        return {
            consentGiven: dto.consentGiven,
            consentType: this.mapApiConsentTypeToFrontend(dto.consentType),
            isOptional: dto.isOptional,
            isDirty: false,
            isValid: null
        };
    }

    private get consentsMapApiToFrontend() {
        const map: { [remotetype: string]: IConsents } = {};
        map[Consents.DataStorageTerms] = IConsents.DataStorageTerms;
        map[Consents.Communication] = IConsents.Communication;
        map[Consents.TermsAndConditions] = IConsents.TermsAndConditions;
        return map;
    }

    private mapApiConsentTypeToFrontend(consenttype: Consents): IConsents {
        return this.consentsMapApiToFrontend[consenttype];
    }

    private mapFrontendConsentTypeToApi(consenttype: IConsents): Consents {
        const swappedMap = swapObjectKeyValues(this.consentsMapApiToFrontend);
        return swappedMap[consenttype];
    }
}